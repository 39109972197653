import { Button, TextField, withStyles } from '@material-ui/core'
import { firestore } from 'firebase'
import React from 'react'
import { compose, withHandlers, withState } from 'recompose'
import { isNumber } from 'util'
import { withFirebase } from '../../Firebase'

const AddServiceChecklist = ({ classes, name, setName, onSave, price, setPrice, nameError, priceError }) => (
  <>
    <TextField
      error={nameError}
      id="name"
      label="Name"
      placeholder="The name of the Service"
      className={classes.textField}
      value={name}
      onChange={e => setName(e.currentTarget.value)}
      margin="normal"
      variant="outlined" />
    <TextField
      error={priceError}
      id="price"
      label="Price"
      placeholder="Price for the service"
      className={classes.textField}
      value={price}
      onChange={e => setPrice(e.currentTarget.value)}
      margin="normal"
      variant="outlined" />
    <Button fullWidth variant="raised" color="primary" size="large" className={classes.button} onClick={onSave}>
      Add New Service
    </Button>
  </>
)

const styles = theme => ({
  textField: {
    display: 'flex',
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: '#FAFAFA',
  },
  button: {
    marginTop: theme.spacing.unit,
  },
})

export default compose(
  withStyles(styles),
  withFirebase,
  withState('name', 'setName', null),
  withState('price', 'setPrice', null),
  withState('nameError', 'setNameError', false),
  withState('priceError', 'setPriceError', false),
  withHandlers({
    onSave: props => () => {
      const { name, price } = props

      if (!name || name.length === 0) {
        return props.setNameError(true)
      }
      props.setNameError(false)


      if (!price || price.length === 0 || isNaN(price)) {
        return props.setPriceError(true)
      }
      props.setPriceError(false)


      props.setName('')
      props.setPrice('')

      /** @type firestore.CollectionReference */
      const mechanicsRef = props.firebase.firestore.collection('available_services')
      mechanicsRef.add({ name, price }).then(() => {
        props.setLastUpdated(Date.now())
      }).catch((err) => {
        console.error(err)
      })
    },
  }),
)(AddServiceChecklist)
