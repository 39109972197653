import React from 'react'
import { Grid, Typography, Paper } from '@material-ui/core'
import Container from '../../Common/Container'
import BreadCrumb from '../../Common/Breadcrumb'
import Info from './info'
import AddMechanicForm from './form'

export default () => (
  <>
    <BreadCrumb title="Mechanics / Add New" />
    <Container style={{ marginTop: 30 }}>
      <Grid container direction="row" spacing={40}>
        <Grid item lg={8} md={12} direction="column" spacing={40}>
          <Paper>
            <div style={{ padding: 20 }}>
              <Typography variant="headline">
                Add a new Mechanic
              </Typography>
              <AddMechanicForm />
            </div>
          </Paper>
        </Grid>
        <Info />
      </Grid>
    </Container>
  </>
)
