import React from 'react'
import BookingsTable from '../../Common/BookingsTable'
import Container from '../../Common/Container'
import Breadcrumb from '../../Common/Breadcrumb'
import PendingAssignments from '../../Home/PendingAssignments'

export default () => (
  <>
    <Breadcrumb title="Bookings / List" />
    <Container>
      <div style={{ marginBottom: 20 }}>
        <PendingAssignments />
      </div>
      <BookingsTable title="All Completed Bookings" query={['status', '==', true]} />
    </Container>
  </>
)
