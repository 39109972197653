import React from 'react'
import { IconButton, Dialog, DialogTitle, DialogContent, Button, withStyles } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { compose, withState, withHandlers } from 'recompose'
import { firestore } from 'firebase'
import TextField from '../../Common/TextField'
import { withFirebase } from '../../Firebase'

const EditIconComp = ({ spare, editSpare, isEditing, onClose, onSave, classes, quantity, setQuantity, price, setPrice, gst, setGst }) => (
  <>
    <IconButton key={`${spare.id}-edit-button`} onClick={editSpare}>
      <EditIcon />
    </IconButton>
    <Dialog fullWidth open={isEditing} onClose={onClose} maxWidth="md">
      <DialogTitle>
Edit Spare -
        {spare.name}
      </DialogTitle>
      <DialogContent>

        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <TextField
            value={quantity}
            onChange={setQuantity}
            placeholder="Update the quantity"
            label="Quantity"
            id="quantity" />
        </div>

        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <TextField
            value={price}
            onChange={setPrice}
            placeholder="Update the total Price paid for the Spareparts"
            label="Price"
            id="price" />
        </div>

        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <TextField
            value={gst}
            onChange={setGst}
            placeholder="Update the GST paid for the Spareparts"
            label="GST"
            id="gst" />
        </div>

        <Button fullWidth variant="raised" color="primary" size="large" className={classes.button} onClick={onSave}>
        Update Spare
        </Button>
        <Button fullWidth variant="flat" color="primary" size="large" className={classes.button} onClick={onClose}>
        Cancel
        </Button>
      </DialogContent>
    </Dialog>
  </>
)

const styles = theme => ({
  button: {
    marginTop: theme.spacing.unit,
  },
})

export default compose(
  withStyles(styles),
  withFirebase,
  withState('isEditing', 'setIsEditing', false),
  withState('quantity', 'setQuantity', props => props.spare.quantity),
  withState('price', 'setPrice', props => props.spare.price),
  withState('gst', 'setGst', props => props.spare.gst),
  withHandlers({
    showDialog: props => () => {
      props.setIsEditing(true)
    },
  }),
  withHandlers({
    editSpare: props => () => {
      props.showDialog()
    },
    onClose: props => () => props.setIsEditing(false),
  }),
  withHandlers({
    onSave: props => (e) => {
      const { quantity, price, gst } = props
      /** @type firestore.CollectionReference */
      const sparesRef = props.firebase.firestore.collection('spares')
      sparesRef.doc(props.spare.id).set({ quantity, price, gst }, { merge: true }).then(() => {
        props.onClose()
      }).catch((err) => {
        console.error(err)
      })
    },
  }),
)(EditIconComp)
