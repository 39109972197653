import React from 'react'
import { compose, withState, lifecycle } from 'recompose'
import { firestore } from 'firebase'
import { withFirebase } from '../Firebase'

const MechanicName = ({ user }) => <span>{user ? `${user.name} (${user.phone})` : '...'}</span>

export default compose(
  withFirebase,
  withState('user', 'setUser', props => parseMechanicFromLocal(props.id)),
  lifecycle({
    async componentDidMount() {
      if (!this.props.user) {
        /** @type firestore.DocumentReference */
        const userRef = this.props.firebase.firestore.doc(`mechanics/${this.props.id}`)
        const user = (await userRef.get()).data()
        localStorage.setItem(this.props.id, JSON.stringify(user))
        this.props.setUser(user)
      }
    },
  }),
)(MechanicName)

function parseMechanicFromLocal (id) {
  const local = localStorage.getItem(id)
  try {
    return JSON.parse(local)
  } catch (err) {
    return null
  }
}
