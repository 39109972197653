import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { compose } from 'recompose'

import * as ROUTES from '../../constants/routes'
import { AuthUserContext } from '../Session'
import SignOutButton from '../SignOut'
import ProfileMenu from './ProfileMenu'
import AddNewMenu from './AddNewMenu'

const Navigation = ({ authUser, ...props }) => (
  <AuthUserContext.Consumer>
    {authUser => (authUser ? <NavigationAuth user={authUser} {...props} /> : <NavigationNonAuth {...props} />)
    }
  </AuthUserContext.Consumer>
)

const NavigationAuth = ({ classes }) => (
  <AppBar position="static">
    <Toolbar>
      <Typography variant="h6" color="inherit" className={classes.grow}>
        EMSeva
        {' '}
        <Link to={ROUTES.LANDING} style={{ textDecoration: 'underline', color: 'white' }}><em>Admin</em></Link>
      </Typography>
      <MenuButton title="Dashboard" link={ROUTES.LANDING} />
      <MenuButton title="Customers" link={ROUTES.CUSTOMERS} />
      <MenuButton title="Bookings" link={ROUTES.BOOKING_LIST} />
      <MenuButton title="Camp Details" link={ROUTES.MANAGE_SERVICE_VANS} />
      <MenuButton title="Mechanics" link={ROUTES.LIST_MECHANIC} />
      <AddNewMenu />
      <ProfileMenu />
      <SignOutButton />
    </Toolbar>
  </AppBar>
)

const MenuButton = ({ title, link }) => (
  <Button color="inherit">
    <Link to={link} style={{ textDecoration: 'none', color: 'white' }}>{title}</Link>
  </Button>
)

const NavigationNonAuth = ({ classes }) => (
  <AppBar position="static">
    <Toolbar>
      <Typography variant="h6" color="inherit" className={classes.grow}>
        EMSeva
        {' '}
        <Link to={ROUTES.LANDING} style={{ textDecoration: 'underline', color: 'white' }}><em>Admin</em></Link>
      </Typography>
    </Toolbar>
  </AppBar>
)

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
}

export default compose(
  withStyles(styles),
)(Navigation)
