import { Grid, IconButton, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { firestore } from 'firebase'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import swal from 'sweetalert'
import * as ROUTES from '../../../constants/routes'
import BrandFromId from '../../Common/BrandFromId'
import Breadcrumb from '../../Common/Breadcrumb'
import { FullWidthPrimaryButton as Btn } from '../../Common/Button'
import Container from '../../Common/Container'
import { withFirebase } from '../../Firebase'
import LocationFromId from '../../Common/LocationFromId'

const MechanicsList = ({
  addMechanic, mechanicsData, deleteMechanic,
}) => (
  <>
    <Breadcrumb title="Mechanics / List" />
    <Container>
      <Grid container alignItems="center">
        <Grid item sm={8}>
          <Typography variant="display1">
              List of Mechanics registered
          </Typography>
        </Grid>
        <Grid item sm={4}>
          <Btn onClick={addMechanic}>
              Add New Mechanic
          </Btn>
        </Grid>
      </Grid>
      <Grid container xs={12} justify="center">
        <Grid item style={{ marginTop: 30 }}>
          <MUIDataTable
            title="Mechanics Registered"
            columns={['ID', 'Name', 'Phone', 'Alternate Phone', 'Email', 'Address', {
              name: 'Brand',
              options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (_, meta) => meta.rowData && meta.rowData[6] && (
                  <BrandFromId id={meta.rowData[6]} />
                ),
              },
            }, {
              name: 'Location',
              options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (_, meta) => meta.rowData && meta.rowData[7] && (
                  <LocationFromId id={meta.rowData[7]} />
                ),
              },
            }, {
              name: 'Delete',
              options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (value, meta) => meta.rowData && meta.rowData[0] && (
                  <IconButton key={meta.rowData[0]} onClick={() => deleteMechanic(meta.rowData[0])}>
                    <DeleteIcon />
                  </IconButton>
                ),
              },
            }]}
            data={mechanicsData}
            options={{ filterType: 'dropdown', filter: true, responsive: 'stacked', selectableRows: 'none' }} />
        </Grid>
      </Grid>
    </Container>
  </>
)

export default compose(
  withFirebase,
  withState('mechanics', 'setMechanics', null),
  withState('lastUpdated', 'setLastUpdated', null),
  withState('mechanicsData', 'setMechanicsData', []),
  withHandlers({
    getMechanics: props => async () => {
      /** @type firestore.CollectionReference */
      const mechanicsRef = props.firebase.firestore.collection('mechanics')
      const _mechanics = await mechanicsRef.get()
      const mechanics = {}
      const mechanicsData = []
      _mechanics.docs.forEach((m) => {
        const data = m.data()
        mechanics[m.id] = data
        mechanicsData.push([
          m.id,
          data.name,
          data.phone,
          data.alternatePhone,
          data.email,
          data.address,
          data.brand,
          data.location,
        ])
      })
      props.setMechanics(mechanics)
      props.setMechanicsData(mechanicsData)
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.getMechanics()
    },
  }),
  withHandlers({
    addMechanic: props => () => {
      props.history.push(ROUTES.ADD_MECHANIC)
    },
    deleteMechanic: props => async (id) => {
      swal({
        title: 'Delete  Mechanic?',
        text: 'Mechanic once deleted cannot be recovered. Are you sure you want to deactivate this mechanic?',
        icon: 'warning',
        dangerMode: true,
        showLoaderOnConfirm: true,
        buttons: {
          cancel: { text: 'Cancel', visible: true, closeModal: true },
          confirm: { text: 'Yes, Delete Mechanic', closeModal: false },
        },
      }).then((value) => {
        if (value) {
          localStorage.removeItem('mechanics')
          /** @type firestore.DocumentReference */
          const membershipRef = props.firebase.firestore.doc(`mechanics/${id}`)
          membershipRef.delete()
            .then(props.getMechanics)
            .then(() => swal('Mechanic Deleted!', 'Mechanic has been deleted successfully', 'success'))
            .catch(console.error)
        }
      }).catch(console.error)
    },
  }),
)(MechanicsList)
