/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React from 'react'
import { compose } from 'recompose'
import { Grid, Typography, Divider } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import { firestore } from 'firebase'
import moment from 'moment'
import { withFirebase } from '../../Firebase'
import Breadcrumb from '../../Common/Breadcrumb'
import Container from '../../Common/Container'
import AddServiceVan from './add'
import MechanicName from '../../Common/MechanicName'
import LocationFromId from '../../Common/LocationFromId'

class ServiceVansList extends React.Component {
  state = {
    serviceVans: [],
  }

  componentDidMount() {
    this.getServiceVans()
  }

  getColumns = () => ([
    'Van ID',
    'Van Name',
    'Van Number',
    'Driver Name',
    'Driver Number',
    {
      name: 'Mechanic',
      options: {
        filter: true,
        sort: true,
        empty: false,
        customBodyRender: (value, meta, updateValue) => meta.rowData && meta.rowData[5] && (
          <Typography variant="body1">
            <MechanicName id={meta.rowData[5]} />
          </Typography>
        ),
      },
    },
    {
      name: 'Location',
      options: {
        filter: true,
        sort: true,
        empty: false,
        customBodyRender: (value, meta, updateValue) => meta.rowData && meta.rowData[6] && (
          <Typography variant="body1">
            <LocationFromId id={meta.rowData[6]} />
          </Typography>
        ),
      },
    },
    'Start Date',
  ])

  getOptions = () => ({
    filterType: 'dropdown',
    filter: true,
    responsive: 'stacked',
    selectableRows: 'none',
    textLabels: { body: { noMatch: 'No Service Vans avaialble. Add a new one!' } },
  })

  getServiceVans = async () => {
    /** @type firestore.CollectionReference */
    const svColl = this.props.firebase.firestore.collection('service_vans')
    const svDocs = await svColl.get()
    const serviceVans = []
    for (const index in svDocs.docs) {
      const _sv = svDocs.docs[index]
      const svIndex = _sv.id
      const svDoc = _sv.data()
      serviceVans.push([
        svIndex,
        svDoc.vanName,
        svDoc.vanNumber,
        svDoc.driverName,
        svDoc.driverNumber,
        svDoc.mechanic,
        svDoc.location,
        svDoc.startDate && svDoc.startDate.seconds ? moment(svDoc.startDate.seconds * 1000).format('DD/MM/YYYY') : '-',
      ])
    }
    this.setState({ serviceVans })
  }

  render() {
    return (
      <>
        <Breadcrumb title="Service Vans / Camp Details" />
        <Container>
          <Grid container spacing={40}>
            <Grid item md={12}>
              <MUIDataTable
                title="Available Service Vans"
                columns={this.getColumns()}
                options={this.getOptions()}
                data={this.state.serviceVans} />
            </Grid>
            <Grid item md={12}>
              <AddServiceVan fetchServiceVans={this.getServiceVans} />
            </Grid>
          </Grid>
        </Container>
      </>
    )
  }
}

export default compose(
  withFirebase,
)(ServiceVansList)
