import React from 'react'
import { Select, MenuItem, withStyles, Grid, CircularProgress, Typography, FormControl, OutlinedInput } from '@material-ui/core'
import { compose } from 'recompose'
import { firestore } from 'firebase'
import { withFirebase } from '../Firebase'

class LocationsDropdown extends React.Component {
  state = {
    currentValue: this.props.value,
    locations: [],
    loading: true,
  }

  setValue = (e) => {
    this.setState({ currentValue: e.target.value })
    this.props.onChange(e.target.value)
  }

  async componentDidMount () {
    const localLocations = localStorage.getItem('locations')
    if (localLocations) {
      this.setState({ locations: JSON.parse(localLocations), loading: false })
    } else {
      /** @type firestore.CollectionReference */
      const locationsColl = this.props.firebase.firestore.collection('locations')
      const locations = await locationsColl.get()
      const finallocations = locations.docs.map(d => ({ id: d.id, ...d.data() }))
      this.setState({ locations: finallocations, loading: false })
      localStorage.setItem('locations', JSON.stringify(finallocations))
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Grid container alignItems="center" justify="center" direction="column" style={{ margin: 30 }}>
          <CircularProgress color="primary" />
          <Typography variant="headline" style={{ padding: 20 }}>
            Loading locations...
          </Typography>
        </Grid>
      )
    }

    return (
      <FormControl fullWidth className={this.props.classes.textField} variant="outlined">
        <Select
          displayEmpty
          fullWidth
          value={this.state.currentValue}
          onChange={this.setValue}
          input={<OutlinedInput />}>
          <MenuItem value="">
            Select a Location
          </MenuItem>
          {this.state.locations.map(m => (
            <MenuItem key={m.id} value={m.id}>
              {m.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}

const styles = theme => ({
  select: {
    fontSize: 30,
  },
  textField: {
    display: 'flex',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: '#FAFAFA',
  },
})

export default compose(
  withStyles(styles),
  withFirebase,
)(LocationsDropdown)
