import React from 'react'
import { Typography, Divider } from '@material-ui/core'
import { compose } from 'recompose'
import { firestore } from 'firebase'
import swal from 'sweetalert'
import moment from 'moment'
import TextField from '../../Common/TextField'
import { withFirebase } from '../../Firebase'
import MechanicDropdown from '../../Common/MechanicsDropdown'
import LocationsDropdown from '../../Common/LocationsDropdown'
import ServiceVansDropdown from '../../Common/ServiceVansDropdown'
import { FullWidthPrimaryButton as Btn } from '../../Common/Button'

class AddServiceVan extends React.Component {
  state = {
    selectedVan: {},
    vanId: '',
    vanName: '',
    vanNumber: '',
    driverName: '',
    driverNumber: '',
    mechanic: '',
    location: '',
    startDate: moment().toDate(),
    endDate: moment().toDate(),
  }

  onRegistrationChange = val => this.setState({ vanNumber: val })

  onDriverNameChange = val => this.setState({ driverName: val })

  onDriverNumberChange = val => this.setState({ driverNumber: val })

  onMechanicChange = val => this.setState({ mechanic: val })

  onLocationChange = val => this.setState({ location: val })

  onDateChange = val => {
    if (
      moment()
        .startOf('day')
        .toDate() > new Date(val)
    ) {
      return swal({
        title: 'Invalid Start Date',
        text: 'Start Date cannot be a date in the past',
        icon: 'error',
      })
    }
    return this.setState({ startDate: new Date(val) })
  }

  onEndDateChange = val => {
    if (
      moment()
        .startOf('day')
        .toDate() > new Date(val)
    ) {
      return swal({
        title: 'Invalid End Date',
        text: 'Start Date cannot be a date in the past',
        icon: 'error',
      })
    }
    if (new Date(val) < this.state.startDate) {
      return swal({
        title: 'Invalid End Date',
        text: 'End Date cannot be a date less than Start Date',
        icon: 'error',
      })
    }
    return this.setState({ endDate: new Date(val) })
  }

  onServiceVanChange = val =>
    this.setState({
      selectedVan: val,
      vanId: val.id,
      vanName: val.name,
      vanNumber: val.registrationNumber,
    })

  showError = (title, message) =>
    swal({
      title,
      text: message,
      icon: 'error',
    })

  saveServiceVan = () => {
    if (!this.state.vanNumber) {
      return this.showError(
        'Van Number Missing',
        'Looks like you forgot to input the Registration number for the service van',
      )
    }

    if (!this.state.driverName) {
      return this.showError(
        "Driver's Name missing",
        'The name of the Driver is required for the Service Van',
      )
    }

    if (!this.state.driverNumber) {
      return this.showError(
        "Missing Driver's Phone number",
        'Please input the phone number of the driver to continue',
      )
    }

    if (this.state.driverNumber.length < 10) {
      return this.showError(
        "Invalid Driver's Phone number",
        "It seems that the Driver's number you entered is invalid",
      )
    }

    if (!this.state.mechanic) {
      return this.showError('Choose a Mechanic', 'Please choose a mechanic for the service van')
    }

    if (!this.state.location) {
      return this.showError(
        'Choose a location!',
        'Please choose a location for the service van. If you wish to add a new location, please visit Manage > Locations',
      )
    }

    /** @type firestore.CollectionReference */
    const svColl = this.props.firebase.firestore.collection('service_vans')
    svColl
      .add(this.state)
      .then(() => {
        this.setState(
          {
            selectedVan: {},
            vanId: '',
            vanName: '',
            vanNumber: '',
            driverName: '',
            driverNumber: '',
            mechanic: '',
            location: '',
            startDate: moment().format('YYYY-MM-DD'),
          },
          () => {
            this.props.fetchServiceVans()
          },
        )
        swal({
          title: 'Done!',
          text: 'The Camp has been created successfully!',
          timer: 3000,
          buttons: false,
          icon: 'success',
        })
      })
      .catch(err => console.error(err))
  }

  render() {
    return (
      <div style={{ paddingTop: 10 }}>
        <Typography variant="display1">Create Camp</Typography>
        <Typography variant="subheading">Fill the below details to create Camp</Typography>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <ServiceVansDropdown value={this.state.vanId} onChange={this.onServiceVanChange} />
        <TextField
          id="driverName"
          label="Driver's Name"
          placeholder="Name of the Driver for the Van"
          value={this.state.driverName}
          onChange={this.onDriverNameChange} />
        <TextField
          id="driverNumber"
          label="Driver's Phone Number"
          type="number"
          placeholder="Phone number of the Driver of the Van"
          value={this.state.driverNumber}
          onChange={this.onDriverNumberChange} />
        <TextField
          id="activeDate"
          label="Start Date"
          placeholder="When to start the Service Van from?"
          type="date"
          value={moment(this.state.startDate).format('YYYY-MM-DD')}
          onChange={this.onDateChange} />
        <TextField
          id="endDate"
          label="End Date"
          placeholder="When to end the Service Van from?"
          type="date"
          value={moment(this.state.endDate).format('YYYY-MM-DD')}
          onChange={this.onEndDateChange} />
        <MechanicDropdown showPhone value={this.state.mechanic} onChange={this.onMechanicChange} />
        <LocationsDropdown value={this.state.location} onChange={this.onLocationChange} />
        <Btn onClick={this.saveServiceVan}>Add Camp</Btn>
      </div>
    )
  }
}

export default compose(withFirebase)(AddServiceVan)
