import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, TableCell, TableRow, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { compose, withHandlers, withState, branch, renderNothing } from 'recompose'
import { firestore } from 'firebase'
import { withFirebase } from '../../Firebase'
import EditIconComponent from './editIcon'

const ServiceItem = ({ id, service, isOpen, handleClose, confirmDelete, deleteService, setLastUpdated }) => (
  <TableRow>
    <TableCell><Typography variant="body1">{service.name}</Typography></TableCell>
    <TableCell>
      <Typography variant="body1">
₹
        {service.price}
/-
      </Typography>
    </TableCell>
    <TableCell style={{ alignItems: 'flex-end' }}>
      <EditIconComponent id={id} data={service} setLastUpdated={setLastUpdated} />
      <IconButton key={`${id}-delete-button`} onClick={deleteService}>
        <DeleteIcon />
      </IconButton>
    </TableCell>
    {isOpen && (
      <Dialog
        key={`${id}-dialog`}
        keepMounted
        open={isOpen}
        TransitionComponent={props => <Slide direction="up" {...props} />}
        onClose={handleClose}>
        <DialogTitle>
          Confirm Delete service from Checklist
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete
            {' '}
            {service.name}
            {' '}
from EMSeva's Checklist
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No, Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary">
            Yes, Delete it!
          </Button>
        </DialogActions>
      </Dialog>
    )}
  </TableRow>
)

export default compose(
  withFirebase,
  withState('isOpen', 'setIsOpen', false),
  withState('isDeleted', 'setIsDeleted', false),
  withHandlers({
    handleClose: props => () => props.setIsOpen(false),
  }),
  withHandlers({
    deleteService: props => () => props.setIsOpen(true),
    confirmDelete: props => () => {
      /** @type firestore.DocumentReference */
      const servicesRef = props.firebase.firestore.doc(`available_services/${props.id}`)
      servicesRef.delete().then(() => props.setIsDeleted(true)).catch(err => console.error(err))
      props.handleClose()
    },
  }),
  branch(
    props => props.isDeleted,
    renderNothing,
  ),
)(ServiceItem)
