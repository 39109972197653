import React from 'react'
import { compose, withReducer, withHandlers, lifecycle, withState } from 'recompose'
import { Grid, Typography, Button, Paper, Divider, Chip } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import swal from '@sweetalert/with-react/dist/sweetalert'

import { firestore } from 'firebase'
import Container from '../../Common/Container'
import Breadcrumb from '../../Common/Breadcrumb'
import { withFirebase } from '../../Firebase'
import * as ROUTES from '../../../constants/routes'

const MembershipsList = ({ addMembership, memberships, deactivateMembership }) => (
  <>
    <Breadcrumb title="Memberships / List" />
    <Container>
      <Grid container direction="column" lg={12}>
        <Grid container direction="row" lg={12} style={{ marginBottom: 20 }}>
          <Grid item lg={10} md={8} sm={6} xs={12}>
            <Typography variant="headline">
              Available Memberships
            </Typography>
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Button fullWidth variant="raised" color="primary" onClick={addMembership}>
              Add New Membership
            </Button>
          </Grid>
        </Grid>
        <Grid item lg={12} spacing={40}>
          {memberships.map(membership => (
            <Paper key={membership.id} style={{ marginTop: 20, marginBottom: 20, padding: 20 }}>
              <Grid container direction="row" alignItems="center" lg={12}>
                <Grid item md={4} xs={12}>
                  <Typography variant="h2">{membership.name}</Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                  <Typography variant="body1">{membership.description}</Typography>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <Grid container direction="row" lg={12} justify="space-between" alignItems="center" spacing={40}>
                <Grid item lg={10} md={8} xs={12}>
                  <Typography variant="subtitle1" color="default">
                    Services Included
                  </Typography>
                  <Typography variant="h5">
                    {membership.services.map(m => (
                      <Chip label={m} style={{ margin: 5, fontSize: 14 }} />
                    ))}
                  </Typography>
                  <Grid container lg={12} direction="row" alignItems="center" alignContent="center" justify="space-between">
                    <Grid item sm={6} xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                      <Typography variant="caption">From</Typography>
                      <span>
                        <Typography variant="display1">
                          {moment(new Date(membership.startDate.seconds * 1000)).format('Do')}
                        </Typography>
                        <Typography variant="body1">
                          {moment(new Date(membership.startDate.seconds * 1000)).format('MMMM, YYYY')}
                        </Typography>
                      </span>
                    </Grid>
                    <Grid item sm={6} xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                      <Typography variant="caption">To</Typography>
                      <span>
                        <Typography variant="display1">
                          {moment(new Date(membership.endDate.seconds * 1000)).format('Do')}
                        </Typography>
                        <Typography variant="body1">
                          {moment(new Date(membership.endDate.seconds * 1000)).format('MMMM, YYYY')}
                        </Typography>
                      </span>
                    </Grid>
                  </Grid>
                  <Button
                    variant="outlined"
                    color="inherit"
                    style={{ marginTop: 20 }}
                    size="small"
                    onClick={() => deactivateMembership(membership.id)}>
                    Deactivate Membership
                  </Button>
                </Grid>
                <Grid item lg={2} md={4} xs={12} alignItems="center">
                  <Typography variant="display3" align="right" color="primary">
                    ₹
                    {' '}
                    {membership.price}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Grid>
      </Grid>
    </Container>
  </>
)

export default compose(
  withFirebase,
  withRouter,
  withState('memberships', 'setMemberships', []),
  withHandlers({
    getMemberships: props => async () => {
      /** @type firestore.CollectionReference */
      const membershipRef = props.firebase.firestore.collection('memberships')
      const _memberships = (await membershipRef.where('active', '==', true).orderBy('created_at', 'desc').get()).docs
      const memberships = _memberships.map(m => ({
        id: m.id,
        ...m.data(),
      }))
      props.setMemberships(memberships)
    },
  }),
  withHandlers({
    addMembership: ({ history }) => () => history.push(ROUTES.MEMBERSHIP_ADD),
    deactivateMembership: props => (id) => {
      swal({
        title: 'Deactivate Membership plan?',
        text: 'Are you sure you want to deactivate this membership?',
        icon: 'warning',
        dangerMode: true,
        showLoaderOnConfirm: true,
        buttons: {
          cancel: { text: 'Cancel', visible: true, closeModal: true },
          confirm: { text: 'Yes, Deactivate Membership', closeModal: false },
        },
      }).then((value) => {
        if (value) {
          /** @type firestore.DocumentReference */
          const membershipRef = props.firebase.firestore.doc(`memberships/${id}`)
          membershipRef.update({ active: false })
            .then(() => props.getMemberships())
            .then(() => swal('Deactivated!', 'Your Membership plan has been deactivated successfully!', 'success'))
            .catch(console.error)
        }
      })
    },
  }),
  lifecycle({
    async componentDidMount () {
      this.props.getMemberships()
    },
  }),
)(MembershipsList)
