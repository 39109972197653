import React from 'react'
import { Button } from '@material-ui/core'

export const FullWidthPrimaryButton = ({ children, ...props }) => (
  <Button
    fullWidth
    variant="contained"
    color="primary"
    size="large"
    style={{ marginTop: 15, marginBottom: 15 }}
    type="submit"
    {...props}>
    {children}
  </Button>
)
