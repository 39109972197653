import React from 'react'
import { compose, withState, lifecycle } from 'recompose'
import { Link } from 'react-router-dom'
import { Grid, CircularProgress, Typography, Table, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core'
import { firestore } from 'firebase'
import { withFirebase } from '../Firebase'
import Breadcrumb from '../Common/Breadcrumb'
import Container from '../Common/Container'

const SearchResults = ({ searchTerm, searchText, searching, error, users }) => (
  <>
    <Breadcrumb title={`Customers / Search / ${searchText}`} />
    <Container>
      <Grid container lg={12} direction="column" spacing={48} alignItems="center">
        {searching && (
          <>
            <CircularProgress />
            <Typography variant="title">
              Searching...
            </Typography>
          </>
        )}
        {!searching && error && (
          <Typography variant="display2">
            {error}
          </Typography>
        )}
        {!searching && !error && users && (
          <>
            <Typography variant="headline">
              Found
              {' '}
              {users.length}
              {' '}
users
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => {
                  const u = user.data()
                  const id = user.id
                  return (
                    <TableRow>
                      <TableCell>
                        <Link to={`/customer/${id}`}>{id}</Link>
                      </TableCell>
                      <TableCell>{u.displayName}</TableCell>
                      <TableCell>{u.email}</TableCell>
                      <TableCell>{u.phoneNumber}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </>
        )}
      </Grid>
    </Container>
  </>
)

SearchResults.displayName = 'SearchResults'

export default compose(
  withState('searchTerm', '_setSearchTerm', props => props.match.params.type),
  withState('searchText', '_setSearchText', props => props.match.params.search),
  withState('searching', 'setSearching', true),
  withState('users', 'setUsers', null),
  withState('error', 'setError', null),
  withFirebase,
  lifecycle({
    async componentDidMount() {
      /** @type firestore.CollectionReference */
      const usersCollection = this.props.firebase.firestore.collection('users')
      /** @type firestore.Query */
      const query = usersCollection.where(this.props.searchTerm, '==', this.props.searchText)
      const res = await query.get()
      if (res.size === 0) {
        this.props.setSearching(false)
        this.props.setError(`Did not find users with ${this.props.searchTerm} "${this.props.searchText}"`)
      }

      this.props.setSearching(false)
      this.props.setUsers(res.docs)
      res.docs.map((user) => {
        console.log(user.id)
      })
    },
  }),
)(SearchResults)
