import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Typography, Grid, Paper, Table, Link } from '@material-ui/core'
import { compose, withState, withHandlers } from 'recompose'
import Papa from 'papaparse'
import { firestore } from 'firebase'
import Breadcrumb from '../../Common/Breadcrumb'
import Container from '../../Common/Container'
import List from './list'
import Add from './add'
import Info from './info'
import { withFirebase } from '../../Firebase'

const ServiceChecklistMain = ({ lastUpdated, setLastUpdated, processData }) => (
  <>
    <Breadcrumb title="Checklist / Services / Manage" />
    <Container>
      <Typography variant="display1">
        Manage your services Checklist
      </Typography>
      <Grid container xs={12} justify="center" style={{ paddingTop: 30 }} spacing={40}>
        <Grid item lg={6} xs={12}>
          <List lastUpdated={lastUpdated} setLastUpdated={setLastUpdated} />
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography variant="headline">
            Add a new service to checklist
          </Typography>
          <MyDropzone onData={processData} />
          <a href="/static/add_service_template.csv">
            <Typography variant="subheading" align="center" style={{ marginBottom: 20 }}>
              You can download the template Here
            </Typography>
          </a>
          <Typography variant="headline" align="center">
            OR
          </Typography>
          <Add setLastUpdated={setLastUpdated} />
          <Info />
        </Grid>
      </Grid>
    </Container>
  </>
)

function MyDropzone(props) {
  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result
      const data = Papa.parse(binaryStr, {
        header: true,
        fastMode: true,
      })
      props.onData(data.data)
    }

    acceptedFiles.forEach(file => reader.readAsBinaryString(file))
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div
      style={{
        padding: 20,
        border: '1px dashed #808080',
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 7,
      }}
      {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive
          ? (
            <Typography variant="headline">
            Drop the files here ...
            </Typography>
          )
          : (
            <Typography variant="headline">
            Drag 'n' drop the CSV file here, or click to select files
            </Typography>
          )
      }
    </div>
  )
}

export default compose(
  withFirebase,
  withState('lastUpdated', 'setLastUpdated', null),
  withHandlers({
    processData: props => (data) => {
      /** @type firestore.CollectionReference */
      const mechanicsRef = props.firebase.firestore.collection('available_services')
      data.forEach((item) => {
        const { name, price } = item
        mechanicsRef.add({ name, price }).then(() => {
          props.setLastUpdated(Date.now())
        }).catch((err) => {
          console.error(err)
        })
      })
    },
  }),
)(ServiceChecklistMain)
