import React from 'react'
import { compose, withState, lifecycle } from 'recompose'
import { firestore } from 'firebase'
import { withFirebase } from '../Firebase'

const NameFromID = ({ user }) => <span>{user ? user.displayName : '...'}</span>

export default compose(
  withFirebase,
  withState('user', 'setUser', props => localStorage.getItem(props.id) && JSON.parse(localStorage.getItem(props.id))),
  lifecycle({
    async componentDidMount() {
      if (!this.props.user) {
        /** @type firestore.DocumentReference */
        const userRef = this.props.firebase.firestore.doc(`users/${this.props.id}`)
        const user = (await userRef.get()).data()
        localStorage.setItem(this.props.id, JSON.stringify(user))
        this.props.setUser(user)
      }
    },
  }),
)(NameFromID)
