import React from 'react'
import { compose, withState, withHandlers } from 'recompose'
import { withStyles, TextField } from '@material-ui/core'

const Component = ({ value, onChangeText, classes, id, label, placeholder, ...props }) => (
  <TextField
    {...props}
    id={id}
    label={label}
    placeholder={placeholder}
    className={classes.textField}
    value={value}
    onChange={onChangeText}
    margin="normal"
    variant="outlined" />
)

const styles = theme => ({
  textField: {
    display: 'flex',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: '#FAFAFA',
  },
})

export default compose(
  withStyles(styles),
  withHandlers({
    onChangeText: props => (e) => {
      props.onChange(e.target.value)
    },
  }),
)(Component)
