import React from 'react'
import { compose } from 'recompose'
import { Grid, Typography, Button, Paper, TextField, Select, OutlinedInput, MenuItem, FormControl, withStyles } from '@material-ui/core'
import Breadcrumb from '../../Common/Breadcrumb'
import Container from '../../Common/Container'
import Info from './info'

class PublishNotifications extends React.Component {
  state = {
    title: null,
    body: null,
    condition: '',
    conditionText: null,
  }

  onChange = (key, value) => {
    this.setState({ [key]: value })
  }

  onCancel = () => this.props.history.goBack();

  render () {
    return (
      <>
        <Breadcrumb title="Notifications / Publish" />
        <Container>
          <Grid container lg={12} direction="row" spacing={40}>
            <Grid item lg={8} md={6} xs={12}>
              <Typography variant="display1">
                Publish a New Notification
              </Typography>
              <div style={{ marginTop: 20 }}>
                <Paper style={{ padding: 20 }}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Notification Title"
                    placeholder="The title of the notification"
                    value={this.state.title}
                    onChange={e => this.onChange('title', e.target.value)}
                    margin="normal"
                    variant="outlined" />
                  <TextField
                    fullWidth
                    multiline
                    id="body"
                    label="Notification Body"
                    placeholder="The body of the notification to be sent"
                    rows={3}
                    value={this.state.body}
                    onChange={e => this.onChange('body', e.target.value)}
                    margin="normal"
                    variant="outlined" />
                  <Grid container direction="row" lg={12}>
                    <Grid item md={6} xs={6} style={{ paddingRight: 10 }}>
                      <FormControl fullWidth className={this.props.classes.textField} variant="outlined">
                        <Select
                          displayEmpty
                          value={this.state.condition}
                          onChange={e => this.onChange('condition', e.target.value)}
                          input={<OutlinedInput />}>
                          <MenuItem value="">Please choose a Category</MenuItem>
                          <MenuItem value="email">Email Address is</MenuItem>
                          <MenuItem value="name">Name is</MenuItem>
                          <MenuItem value="membership">Membership is</MenuItem>
                          <MenuItem value="phone">Phone Number is</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={6} style={{ paddingLeft: 10 }}>
                      <TextField
                        fullWidth
                        id="name"
                        label="Equals to"
                        placeholder="The selected condition equals to"
                        value={this.state.conditionText}
                        onChange={e => this.onChange('conditionText', e.target.value)}
                        margin="normal"
                        variant="outlined" />
                    </Grid>
                    <Button variant="raised" color="primary" size="large">
                      Send Notification
                    </Button>
                    <Button variant="flat" color="primary" style={{ marginLeft: 20 }} onClick={this.onCancel}>
                      Cancel
                    </Button>
                  </Grid>
                </Paper>
              </div>
            </Grid>
            <Info />
          </Grid>
        </Container>
      </>
    )
  }
}

const styles = theme => ({
  textField: {
    display: 'flex',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: '#FAFAFA',
  },
})

export default compose(
  withStyles(styles),
)(PublishNotifications)
