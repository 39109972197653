import React from 'react'
import { compose } from 'recompose'
import { Grid, Paper } from '@material-ui/core'
import { withAuthorization } from '../Session'
import Container from '../Common/Container'
import Breadcrumb from '../Common/Breadcrumb'
import NumberGrid from './NumberGrid'
import PendingComplaints from './PendingComplaints'
import ServicesScheduled from './ServicesScheduled'
import PendingAssignments from './PendingAssignments'
import BookingChart from '../Common/DateChart'
import MembershipChart from './MembershipPieChart'

const HomePage = ({ users, bookings, history }) => (
  <>
    <Breadcrumb title="Dashboard" />
    <Container>
      <NumberGrid />
      <Grid container spacing={40} style={{ marginTop: 20 }}>
        <Grid item sm={12}>
          <Paper style={{ padding: 20 }}>
            <BookingChart
              title="Scheduled Bookings"
              collection="bookings"
              dateField="date"
              seriesName="Number of Bookings"
              loadingText="Loading scheduled Bookings" />
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper style={{ padding: 20 }}>
            <BookingChart
              title="Complaints Registered"
              collection="complaints"
              dateField="created_at"
              seriesName="Number of Complaints"
              loadingText="Loading Complaints" />
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper style={{ padding: 20 }}>
            <MembershipChart />
          </Paper>
        </Grid>
      </Grid>
      <PendingAssignments />
      <Grid container direction="row" style={{ marginTop: 20 }} spacing={40}>
        <PendingComplaints />
        <ServicesScheduled history={history} />
      </Grid>
    </Container>
  </>
)

const condition = authUser => !!authUser

export default compose(
  withAuthorization(condition),
)(HomePage)
