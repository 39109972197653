import React from 'react'
import { Select, MenuItem, withStyles, Grid, CircularProgress, Typography, FormControl, OutlinedInput } from '@material-ui/core'
import { compose } from 'recompose'
import { firestore } from 'firebase'
import { withFirebase } from '../Firebase'

class MechanicsDropdown extends React.Component {
  state = {
    currentValue: this.props.value || '',
    mechanics: [],
    loading: true,
  }

  async componentDidMount () {
    const localMechanics = localStorage.getItem('mechanics')
    if (localMechanics) {
      this.setState({ mechanics: JSON.parse(localMechanics), loading: false })
    } else {
      /** @type firestore.CollectionReference */
      const mechanicsColl = this.props.firebase.firestore.collection('mechanics')
      const mechanics = await mechanicsColl.get()
      const finalMechanics = mechanics.docs.map(d => ({ id: d.id, ...d.data() }))
      this.setState({ mechanics: finalMechanics, loading: false })
      localStorage.setItem('mechanics', JSON.stringify(finalMechanics))
    }
  }

  setValue = (e) => {
    this.setState({ currentValue: e.target.value })
    this.props.onChange(e.target.value)
  }

  render() {
    if (this.state.loading) {
      return (
        <Grid container alignItems="center" justify="center" direction="column" style={{ margin: 30 }}>
          <CircularProgress color="primary" />
          <Typography variant="headline" style={{ padding: 20 }}>
            Loading Mechanics...
          </Typography>
        </Grid>
      )
    }

    return (
      <FormControl fullWidth className={this.props.classes.textField} variant="outlined">
        <Select
          displayEmpty
          fullWidth
          value={this.state.currentValue}
          onChange={this.setValue}
          input={<OutlinedInput />}>
          <MenuItem value="">
            Select a Mechanic
          </MenuItem>
          {this.state.mechanics.map(m => (
            <MenuItem key={m.id} value={m.id}>
              {m.name}
              {this.props.showPhone && ` (${m.phone})`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}

const styles = theme => ({
  select: {
    fontSize: 30,
  },
  textField: {
    display: 'flex',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: '#FAFAFA',
  },
})

export default compose(
  withStyles(styles),
  withFirebase,
)(MechanicsDropdown)
