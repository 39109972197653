import app from 'firebase/app'
import 'firebase/auth'

const config = {
  apiKey: 'AIzaSyAuHMBv52C9rdb764yV05dvbzmKhlYM9pU',
  authDomain: 'emseva-d3a50.firebaseapp.com',
  databaseURL: 'https://emseva-d3a50.firebaseio.com',
  projectId: 'emseva-d3a50',
  storageBucket: 'emseva-d3a50.appspot.com',
  messagingSenderId: '766956669422',
}

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.app = app
    this.auth = app.auth()
    this.firestore = app.firestore()
    this.codeInput = null
    app.auth().languageCode = 'en'
  }

  signInWithMobile = (mobileNumber, verifier) => new Promise((resolve, reject) => {
    const promise = this.auth.signInWithPhoneNumber(mobileNumber, verifier)
    promise.then((codeConfirmation) => {
      console.log('Code Sent', codeConfirmation)
      this.codeInput = codeConfirmation
      resolve(this.codeInput)
    }).catch(reject)
  })

  confirmMobileNumber = code => this.codeInput.confirm(code)

  signIn = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();

  resetPassword = email => this.auth.sendPasswordResetEmail(email);

  updatePassword = password => this.auth.currentUser.updatePassword(password);
}

export default Firebase
