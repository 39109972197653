import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { compose, branch, renderNothing } from 'recompose'

const UserDetails = ({ user }) => (
  <Grid container direction="column" lg={12}>
    <Grid container direction="row" lg={12}>
      <Grid item lg={6} xs={12}>
        <Typography variant="title" gutterBottom>
          Name:
          {' '}
          {user.displayName}
        </Typography>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Typography variant="title" gutterBottom>
          Last Login:
          {' '}
          {new Date(user.lastLogin.seconds * 1000).toISOString()}
        </Typography>
      </Grid>
    </Grid>
    <Grid container direction="row" lg={12}>
      <Grid item lg={6} xs={12}>
        <Typography variant="title" gutterBottom>
          Email:
          {' '}
          {user.email}
        </Typography>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Typography variant="title" gutterBottom>
          Phone:
          {' '}
          {user.phoneNumber}
        </Typography>
      </Grid>
    </Grid>
    <Grid container direction="row" lg={12}>
      <Grid item lg={6} xs={12}>
        <Typography variant="title" gutterBottom>
          Address:
          {' '}
          {user.profile.address}
        </Typography>
      </Grid>
    </Grid>
    <Grid container direction="row" lg={12}>
      <Grid item lg={6} xs={12}>
        <Typography variant="title" gutterBottom>
          Membership:
          {' '}
          {user.membership ? user.membership.text : 'Not Set'}
        </Typography>
      </Grid>
    </Grid>
    <Grid container direction="row" lg={12}>
      <Grid item lg={6} xs={12}>
        <Typography variant="title" gutterBottom>
          Vehicle:
          {' '}
          {Object.values(user.vehicle_details).join(', ')}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
)

export default compose(
  branch(
    props => !props.user,
    renderNothing,
  ),
)(UserDetails)
