import React from 'react'
import { Typography, Grid } from '@material-ui/core'

export default () => (
  <Grid item md={4} xs={12}>
    <Typography variant="display1" gutterBottom style={{ marginBottom: 20 }}>
      Important Information
    </Typography>

    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <Typography variant="headline">Notification Title</Typography>
      <Typography variant="body1">
        The title of the notification, typically shown as bold in a notification
      </Typography>
    </div>

    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <Typography variant="headline">Notification Body</Typography>
      <Typography variant="body1">
        The actual content of the notification.
      </Typography>
    </div>

    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <Typography variant="headline">Category</Typography>
      <Typography variant="body1">
        The Condition to send the notification on
      </Typography>
    </div>

    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <Typography variant="headline">Equals to</Typography>
      <Typography variant="body1">
        The value to fulfil the condition
      </Typography>
    </div>

  </Grid>
)
