import React from 'react'
import { Grid, Typography, Table, TableHead, TableCell, TableBody, TableRow, Paper, Button } from '@material-ui/core'
import { compose } from 'recompose'
import { firestore } from 'firebase'
import Breadcrumb from '../../Common/Breadcrumb'
import Container from '../../Common/Container'
import { withFirebase } from '../../Firebase'
import AddSpare from './add'
import EditIcon from './editIcon'

class InventoryList extends React.Component {
  state = { spares: [], isFreezed: false };

  listener = null;

  freezeListener = null;

  componentDidMount() {
    /** @type firestore.CollectionReference */
    const sparesRef = this.props.firebase.firestore.collection('spares')
    this.listener = sparesRef.onSnapshot((snapshot) => {
      const spares = []
      snapshot.docs.forEach((s) => {
        spares.push({
          id: s.id,
          ...s.data(),
        })
      })
      this.setState({ spares })
    })

    this.setFreezeListener()
  }

  componentWillUnmount() {
    this.listener()
    this.freezeListener()
  }

  freezeButton = () => (
    <Button variant="raised" color="primary" onClick={this.toggleFreeze}>
      Freeze Inventory to Add / Edit
    </Button>
  )

  unfreezeButton = () => (
    <Button variant="raised" color="secondary" onClick={this.toggleFreeze}>
      Unfreeze Inventory
    </Button>
  )

  toggleFreeze = () => {
    /** @type firestore.DocumentReference */
    const settingsRef = this.props.firebase.firestore.doc('settings/emseva-settings')
    settingsRef
      .set({ spares_freezed: !this.state.isFreezed }, { merge: true })
      .catch(console.error)
  }

  setFreezeListener = () => {
    /** @type firestore.DocumentReference */
    const settingsRef = this.props.firebase.firestore.doc('settings/emseva-settings')
    this.freezeListener = settingsRef.onSnapshot((snapshot) => {
      const data = snapshot.data()
      this.setState({ isFreezed: data.spares_freezed })
    })
  }

  render() {
    return (
      <>
        <Breadcrumb title="Inventory / List" rightComponent={this.state.isFreezed ? this.unfreezeButton() : this.freezeButton()} />
        <Container>
          <Grid container direction="row" justify="space-between" spacing={40}>
            <Grid item lg={8} md={12}>
              <Paper>
                <div style={{ padding: 20 }}>
                  <Typography variant="headline">
                  List of Available Spares
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><Typography variant="subheading">Spare</Typography></TableCell>
                        <TableCell><Typography variant="subheading">Quantity</Typography></TableCell>
                        <TableCell><Typography variant="subheading">Price</Typography></TableCell>
                        <TableCell><Typography variant="subheading">GST</Typography></TableCell>
                        {this.state.isFreezed && (
                          <TableCell><Typography variant="subheading" /></TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.spares.map(spare => (
                        <TableRow key={spare.id}>
                          <TableCell><Typography variant="body1">{spare.name}</Typography></TableCell>
                          <TableCell><Typography variant="body1">{spare.quantity}</Typography></TableCell>
                          <TableCell><Typography variant="body1">{spare.price}</Typography></TableCell>
                          <TableCell><Typography variant="body1">{spare.gst}</Typography></TableCell>
                          {this.state.isFreezed && (
                            <TableCell>
                              <EditIcon spare={spare} />
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Paper>
            </Grid>
            <AddSpare isFreezed={this.state.isFreezed} />
          </Grid>
        </Container>
      </>
    )
  }
}

export default compose(
  withFirebase,
)(InventoryList)
