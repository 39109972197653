import React from 'react'
import MUIDataTable from 'mui-datatables'
import swal from 'sweetalert'
import { withRouter } from 'react-router-dom'
import { firestore } from 'firebase'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { compose } from 'recompose'
import { IconButton, Grid } from '@material-ui/core'
import moment from 'moment'
import Breadcrumb from '../../Common/Breadcrumb'
import Container from '../../Common/Container'
import { withFirebase } from '../../Firebase'
import NameFromID from '../../Common/NameFromID'
import * as ROUTES from '../../../constants/routes'

class ComplaintsList extends React.Component {
  state = {
    complaints: [],
  }

  componentDidMount () {
    this.getComplaints()
  }

  viewComplaint = (id) => {
    this.props.history.push(`/complaint/${id}`)
  }

  getComplaints = async () => {
    /** @type firestore.CollectionReference */
    const complaintsColl = this.props.firebase.firestore.collection('complaints')
    const complaintDocs = await complaintsColl.get()
    const complaints = []
    for (const index in complaintDocs.docs) {
      const _c = complaintDocs.docs[index]
      const complaintId = _c.id
      const complaint = _c.data()
      complaints.push([
        complaintId,
        complaint.user_id,
        complaint.subject,
        moment(new Date(complaint.created_at.seconds * 1000)).format('DD MMM YYYY hh:mm'),
        complaint.status,
        complaint,
      ])
    }
    this.setState({ complaints })
  }

  getColumns = () => [
    'Complaint ID',
    {
      name: 'Customer Name',
      options: {
        filter: true,
        sort: true,
        empty: false,
        customBodyRender: (value, meta, updateValue) => meta.rowData && meta.rowData[1] && (
          <NameFromID id={meta.rowData[1]} />
        ),
      },
    },
    'Subject',
    'Created At',
    {
      name: 'Status',
      options: {
        filter: true,
        sort: true,
        empty: false,
        customBodyRender: (value, meta, updateValue) => meta.rowData && (
          meta.rowData[4] ? <span>Completed</span> : <span>Pending</span>
        ),
      },
    },
    {
      name: 'View / Reply',
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value, meta, updateValue) => meta.rowData && meta.rowData[0] && (
          <IconButton key={meta.rowData[0]} onClick={() => this.viewComplaint(meta.rowData[0])}>
            <VisibilityIcon />
          </IconButton>
        ),
      },
    },
  ]

  getOptions = () => ({
    filterType: 'dropdown',
    filter: true,
    responsive: 'stacked',
    selectableRows: 'none',
    textLabels: { body: { noMatch: 'Hurray, no Complaints!' } },
    ...this.props.options,
  })

  render() {
    return (
      <>
        <Breadcrumb title="Complaints / List" />
        <Container>
          <Grid container xs={12} justify="center">
            <Grid item style={{ marginTop: 30 }}>
              <MUIDataTable
                title="All Complaints"
                columns={this.getColumns()}
                data={this.state.complaints}
                options={this.getOptions()} />
            </Grid>
          </Grid>
        </Container>
      </>
    )
  }
}

export default compose(
  withFirebase,
  withRouter,
)(ComplaintsList)
