import React from 'react'
import GoogleMapLoader from 'react-google-maps-loader'
import GooglePlacesSuggest from 'react-google-places-suggest'
import MUIDataTable from 'mui-datatables'
import { compose } from 'recompose'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import { firestore } from 'firebase'
import Breadcrumb from '../Common/Breadcrumb'
import Container from '../Common/Container'
import { withFirebase } from '../Firebase'
import AutoSuggest from './autoSuggest'
import TextField from '../Common/TextField'
import { FullWidthPrimaryButton as Btn } from '../Common/Button'

class LocationsPage extends React.Component {
  state = {
    geo: {},
    originalPrediction: {},
    radius: 5,
    name: '',
    availableLocations: [],
    loading: true,
  }

  getColumns = () => ([
    'Location ID',
    'Location Name',
    'Location Address',
    'Radius',
    '',
  ])

  getOptions = () => ({
    filterType: 'dropdown',
    filter: true,
    responsive: 'stacked',
    selectableRows: 'none',
    textLabels: { body: { noMatch: 'No Locations added. Add a new one!' } },
  })

  componentDidMount () {
    this.getLocations()
  }

  getLocations = async () => {
    /** @type firestore.CollectionReference */
    const locationColl = this.props.firebase.firestore.collection('locations')
    const locations = await locationColl.get()
    const availableLocations = []
    for (const index in locations.docs) {
      const _location = locations.docs[index]
      const locationId = _location.id
      const location = _location.data()
      availableLocations.push([
        locationId,
        location.name,
        location.geo.formatted_address,
        location.radius,
        '',
      ])
    }
    this.setState({ availableLocations, loading: false })
  }

  onGoogleMapsChange = (_geo, originalPrediction) => {
    const geo = _geo
    geo.geometry.location = {
      lat: geo.geometry.location.lat(),
      lng: geo.geometry.location.lng(),
    }
    delete geo.geometry.viewport
    delete geo.geometry.bounds
    this.setState({ geo, originalPrediction })
  }

  onRadiusChange = radius => this.setState({ radius })

  onNameChange = name => this.setState({ name })

  saveLocation = () => {
    /** @type firestore.CollectionReference */
    const locationColl = this.props.firebase.firestore.collection('locations')
    locationColl.add({
      name: this.state.name,
      radius: this.state.radius,
      geo: this.state.geo,
      originPrediction: this.state.originalPrediction,
      created_at: new Date(),
    }).then(() => {
      this.setState({ geo: {}, originPrediction: {}, name: '', radius: 5 })
      this.getLocations()
      localStorage.removeItem('locations')
    })
  }

  render () {
    return (
      <>
        <Breadcrumb title="Locations" />
        <Container>
          <Grid container spacing={40}>
            <Grid item md={8}>
              {this.state.loading && (
                <Grid container alignItems="center" justify="center" direction="column" style={{ margin: 30 }}>
                  <CircularProgress color="primary" />
                  <Typography variant="headline" style={{ padding: 20 }}>
                    Loading Locations...
                  </Typography>
                </Grid>
              )}
              {!this.state.loading && (
                <MUIDataTable
                  title="Saved Locations"
                  columns={this.getColumns()}
                  options={this.getOptions()}
                  data={this.state.availableLocations} />
              )}
            </Grid>
            <Grid item md={4}>
              <Typography variant="display1">
                Add a new Location
              </Typography>
              <Typography variant="headline">
                Search for the address and set the distance
              </Typography>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <TextField
                  id="locationName"
                  label="Location Name"
                  placeholder="A Friendly name for the location"
                  value={this.state.name}
                  onChange={this.onNameChange} />
                <AutoSuggest onChange={this.onGoogleMapsChange} />
                <TextField
                  id="radisu"
                  label="Service Radius (in km)"
                  placeholder="Radius in KMs where the service is possible"
                  value={this.state.radius}
                  onChange={this.onRadiusChange} />
                <Btn onClick={this.saveLocation}>
                  Save Location
                </Btn>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>
    )
  }
}

export default compose(
  withFirebase,
)(LocationsPage)
