import React from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'

export default ({ text, children }) => (
  <Grid container alignItems="center">
    <Grid item lg={4} />
    <Grid item lg={4}>
      <Paper style={{ padding: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom: 20 }}>
          <img src="/static/images/logo.png" alt="logo" height="128" />
        </div>
        <Typography gutterBottom align="center" variant="subheading" style={{ marginBottom: 20 }}>
          {text}
        </Typography>
        {children}
      </Paper>
    </Grid>
    <Grid item lg={4} />
  </Grid>
)
