import React from 'react'
import { compose } from 'recompose'
import { Grid, Typography, IconButton } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import swal from 'sweetalert'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { firestore } from 'firebase'
import { FullWidthPrimaryButton as Btn } from '../Common/Button'
import { withFirebase } from '../Firebase'
import Container from '../Common/Container'
import Breadcrumb from '../Common/Breadcrumb'

class CustomersList extends React.Component {
  state = {
    customers: [],
  }

  async componentDidMount() {
    /** @type firestore.CollectionReference */
    const customersRef = this.props.firebase.firestore.collection('users')
    const _customers = await customersRef.get()
    const customers = []
    _customers.forEach((c) => {
      const customer = c.data()
      customers.push([
        c.id,
        customer.displayName,
        customer.phoneNumber,
        customer.email,
        customer.profile && customer.profile.address,
        customer.vehicle_details && customer.vehicle_details.model,
        customer.membership && customer.membership.text,
      ])
    })
    this.setState({ customers })
  }

  viewCustomer = (id) => {
    this.props.history.push(`/customer/${id}`)
  }

  render() {
    return (
      <>
        <Breadcrumb title="Customers / List" />
        <Container>
          <Typography variant="display1">
            EMSeva Customers
          </Typography>
          <Grid container xs={12} justify="center">
            <Grid item style={{ marginTop: 30 }}>
              <MUIDataTable
                title="Customers"
                columns={['ID', 'Name', 'Phone', 'Email', 'Address', 'Vehicle Model', 'Membership', {
                  name: 'View',
                  options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    customBodyRender: (value, meta, updateValue) => meta.rowData && meta.rowData[0] && (
                      <IconButton key={meta.rowData[0]} onClick={() => this.viewCustomer(meta.rowData[0])}>
                        <VisibilityIcon />
                      </IconButton>
                    ),
                  },
                }]}
                data={this.state.customers}
                options={{ filterType: 'dropdown', filter: true, responsive: 'stacked', selectableRows: 'none' }} />
            </Grid>
          </Grid>
        </Container>
      </>
    )
  }
}

export default compose(
  withFirebase,
)(CustomersList)
