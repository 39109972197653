import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, Grid } from '@material-ui/core'
import { compose, withState, lifecycle } from 'recompose'
import { firestore } from 'firebase'
import { withFirebase } from '../Firebase'
import BookingsTable from '../Common/BookingsTable'

export default ({ userId }) => (
  <Grid container direction="column" spacing={24} alignItems="center">
    <BookingsTable title="Bookings by Customer" query={['user_id', '==', userId]} />
  </Grid>
)

// export default compose(
//   withFirebase,
//   withState('loading', 'setLoading', true),
//   withState('bookings', 'setBookings', null),
//   lifecycle({
//     async componentDidMount() {
//       const userId = this.props.userId;
//       /** @type firestore.CollectionReference */
//       const bookingsColl = this.props.firebase.firestore.collection('bookings');
//       const userBookingsRef = await bookingsColl.where('user_id', '==', userId).get();
//       this.props.setBookings(userBookingsRef.docs);
//       this.props.setLoading(false);
//     }
//   })
// )(Bookings);
