import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose, withState, withProps, withHandlers } from 'recompose'
import { Menu, MenuItem, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import * as ROUTES from '../../constants/routes'

const AddNewMenu = ({
  anchor, isMenuOpen, closeMenu, openMenu, manageServiceVanMaster, servicesCheclist, viewMemberships,
  manageInventory, viewNotifications, manageLocations,
}) => (
  <>
    <Button color="inherit" onClick={openMenu}>
      <AddIcon />
      Manage
    </Button>
    <Menu
      anchorEl={anchor}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={closeMenu}>
      <MenuItem onClick={manageServiceVanMaster}>Service Vans Master</MenuItem>
      <MenuItem onClick={manageLocations}>Locations</MenuItem>
      <MenuItem onClick={servicesCheclist}>Services Checklist</MenuItem>
      <MenuItem onClick={viewMemberships}>Memberships</MenuItem>
      <MenuItem onClick={viewNotifications}>Notifications</MenuItem>
      <MenuItem onClick={manageInventory}>Manage Inventory</MenuItem>
    </Menu>
  </>
)

export default compose(
  withRouter,
  withState('anchor', 'setAnchor', null),
  withHandlers({
    closeMenu: props => () => props.setAnchor(null),
    openMenu: props => e => props.setAnchor(e.currentTarget),
  }),
  withHandlers({
    manageServiceVanMaster: (props) => {
      props.history.push(ROUTES.SERVICE_VAN_MASTER)
      props.closeMenu()
    },
    manageLocations: (props) => {
      props.history.push(ROUTES.MANAGE_LOCATIONS)
      props.closeMenu()
    },
    servicesCheclist: (props) => {
      props.history.push(ROUTES.SERVICES_CHECKLIST)
      props.closeMenu()
    },
    viewMemberships: (props) => {
      props.history.push(ROUTES.MEMBERSHIP_VIEW)
      props.closeMenu()
    },
    viewNotifications: (props) => {
      props.history.push(ROUTES.NOTIFICATIONS_LIST)
      props.closeMenu()
    },
    manageInventory: (props) => {
      props.history.push(ROUTES.INVENTORY_MANAGE)
      props.closeMenu()
    },
  }),
  withProps(props => ({ isMenuOpen: Boolean(props.anchor) })),
)(AddNewMenu)
