import React from 'react'
import { compose, withHandlers } from 'recompose'
import { withRouter } from 'react-router-dom'
import { Grid, Button, Typography } from '@material-ui/core'
import Container from '../../Common/Container'
import Breadcrumb from '../../Common/Breadcrumb'
import * as ROUTES from '../../../constants/routes'

const ListNotifications = ({ publishNotificaitons }) => (
  <>
    <Breadcrumb title="Notifications / Publish" />
    <Container>
      <Grid container lg={12} direction="row">
        <Grid item lg={10} md={8} xs={12}>
          <Typography variant="display1">
            Notifications Published
          </Typography>
          <div style={{ marginTop: 40 }}>
            <Typography variant="display2" gutterBottom>
              There are no Notifications Published Yet
            </Typography>
            <Typography variant="headline">
              To publish a new notification, tap on the Publish Notification button.
            </Typography>
          </div>
        </Grid>
        <Grid item lg={2} md={4} xs={12}>
          <Button fullWidth variant="raised" size="large" color="primary" onClick={publishNotificaitons}>
            Publish Notification
          </Button>
        </Grid>
      </Grid>
    </Container>
  </>
)

export default compose(
  withRouter,
  withHandlers({
    publishNotificaitons: ({ history }) => () => history.push(ROUTES.NOTIFICATIONS_PUBLISH),
  }),
)(ListNotifications)
