import React from 'react'
import { compose } from 'recompose'
import { firestore } from 'firebase'
import { Grid, CircularProgress, Typography, Divider } from '@material-ui/core'
import moment from 'moment'
import swal from 'sweetalert'
import Breadcrumb from '../../Common/Breadcrumb'
import Container from '../../Common/Container'
import { withFirebase } from '../../Firebase'
import NameFromID from '../../Common/NameFromID'
import TextField from '../../Common/TextField'
import { FullWidthPrimaryButton as Btn } from '../../Common/Button'
import { sendSMSUser } from '../../../helpers/sendSMS'

class ViewComplaint extends React.Component {
  state = {
    complaint: {},
    loading: true,
    message: '',
  }

  componentDidMount() {
    this.getComplaint()
  }

  setMessage = val => this.setState({ message: val })

  getComplaint = async () => {
    /** @type firestore.DocumentReference */
    const complaintDocRef = this.props.firebase.firestore.doc(
      `complaints/${this.props.match.params.id}`,
    )
    const complaintDoc = await complaintDocRef.get()
    const responsesRef = complaintDocRef.collection('responses')
    const responsesDoc = await responsesRef.get()
    const complaint = {
      id: complaintDoc.id,
      ...complaintDoc.data(),
      responses: responsesDoc.docs.map(r => ({ id: r.id, ...r.data() })),
    }
    this.setState({ complaint, loading: false })
  }

  sendMessage = async () => {
    /** @type firestore.DocumentReference */
    const complaintDocRef = this.props.firebase.firestore.doc(
      `complaints/${this.props.match.params.id}`,
    )
    complaintDocRef
      .collection('responses')
      .add({
        created_at: new Date(),
        from: 'emseva',
        message: this.state.message.replace(/\n/g, '\\n'),
      })
      .then(() => {
        this.setState({ message: '' })
        this.getComplaint()
        swal({
          title: 'Message Sent',
          text: 'Message has been sent to the Customer',
          icon: 'success',
          button: false,
          timer: 2000,
        })
        return sendSMSUser(
          this.state.complaint.user_id,
          `You have a new response from EMSeva to your complaint ${
            this.state.complaint.subject
          }. Please visit the app for more information`,
        ).then(console.log).catch(console.error)
      })
      .catch(console.error)
  }

  render() {
    if (this.state.loading) {
      return (
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          style={{ margin: 30 }}>
          <CircularProgress color="primary" />
          <Typography variant="headline" style={{ padding: 20 }}>
            Loading Complaint...
          </Typography>
        </Grid>
      )
    }

    return (
      <>
        <Breadcrumb title={`Complaint / ${this.props.match.params.id}`} />
        <Container>
          <Grid container spacing={40} style={{ paddingTop: 30 }}>
            <Grid item lg={8}>
              <Typography variant="caption">Complaint By</Typography>
              <Typography variant="headline" gutterBottom>
                <NameFromID id={this.state.complaint.user_id} />
              </Typography>
              <Typography variant="caption">Subject</Typography>
              <Typography variant="headline" gutterBottom>
                {this.state.complaint.subject}
              </Typography>
              <Divider style={{ marginBottom: 20 }} />
              <Typography variant="caption">Message</Typography>
              <Typography variant="headline" gutterBottom style={{ marginBottom: 50 }}>
                <pre>{this.state.complaint.message.replace(/\\n/g, '\n')}</pre>
              </Typography>
              {this.state.complaint.responses
                && this.state.complaint.responses.map(r => (
                  <>
                    <Divider style={{ marginBottom: 20, marginTop: 20 }} />
                    <Typography variant="subheading">
                      On
                      {' '}
                      {moment(new Date(r.created_at.seconds * 1000)).format(
                        'Do MMMM, YYYY hh:mm A',
                      )}
                      {' '}
                      {r.from.toUpperCase()}
                      {' '}
responded:
                    </Typography>
                    <Typography variant="headline">
                      <pre>{r.message.replace(/\\n/g, '\n')}</pre>
                    </Typography>
                  </>
                ))}
            </Grid>
            <Grid item lg={4}>
              <TextField
                id="response"
                label="Your Response"
                placeholder="Message you want to send to the Customer..."
                multiline
                rows={15}
                value={this.state.message}
                onChange={this.setMessage} />
              <Btn onClick={this.sendMessage}>Send Message</Btn>
            </Grid>
          </Grid>
        </Container>
      </>
    )
  }
}

export default compose(withFirebase)(ViewComplaint)
