/* eslint-disable no-param-reassign */
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { firestore } from 'firebase'
import moment from 'moment'
import React, { Component } from 'react'
import Chart from 'react-highcharts'
import { withFirebase } from '../Firebase'

class MembershipChart extends Component {
  state = {
    loading: true,
    data: [],
  }

  componentDidMount() {
    this.getUserData()
  }

  getUserData = async () => {
    /** @type firestore.CollectionReference */
    const ref = this.props.firebase.firestore.collection('users')
    const docs = await ref.get()

    const user = docs.docs
      .map(b => b.data().membership && b.data().membership.text || 'Not a member')
      .reduce((prev, next) => ({ ...prev, [next]: (prev[next] || 0) + 1 }), {})

    this.setState({ data: user, loading: false })
  }

  render() {
    if (this.state.loading) {
      return (
        <Grid container alignItems="center" justify="center" direction="column" style={{ margin: 30 }}>
          <CircularProgress color="primary" />
          <Typography variant="headline" style={{ padding: 20 }}>
            {'Loading User Memberships...'}
          </Typography>
        </Grid>
      )
    }

    const { data } = this.state
    const config = {
      chart: { type: 'pie' },
      title: {
        text: 'Membership distribution',
      },
      series: [{
        name: 'User Memberships',
        data: Object.keys(data).map(d => ({
          name: d,
          y: data[d],
        })),
      }],
      credits: false,
    }

    return (
      <Chart config={config} />
    )
  }
}

export default withFirebase(MembershipChart)
