import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

export default ({ text, value, href }) => (
  <Grid item lg={2} md={4} sm={6} xs="auto" style={{ padding: 20 }} alignItems="center">
    <LinkWrapper href={href}>
      <Typography variant="display2" align="center" color="textPrimary">
        {value}
      </Typography>
      <Typography variant="body1" align="center">
        {text}
      </Typography>
    </LinkWrapper>
  </Grid>
)

const LinkWrapper = ({ href, children }) => (!href ? children : (
  <Link to={href} style={{ textDecoration: 'none', color: '#000' }}>
    {children}
  </Link>
))
