import React from 'react'
import { compose, withState, withHandlers, withProps } from 'recompose'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'

const ProfileMenu = ({ openMenu, closeMenu, isMenuOpen, anchor, openProfile }) => (
  <>
    <IconButton
      aria-haspopup="true"
      onClick={openMenu}
      color="inherit">
      <AccountCircle />
    </IconButton>
    <Menu
      anchorEl={anchor}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={closeMenu}>
      <MenuItem onClick={openProfile}>Profile</MenuItem>
      <MenuItem onClick={openProfile}>My account</MenuItem>
    </Menu>
  </>
)

export default compose(
  withState('anchor', 'setAnchor', null),
  withHandlers({
    closeMenu: props => () => {
      props.setAnchor(null)
    },
    openMenu: props => (e) => {
      props.setAnchor(e.currentTarget)
    },
  }),
  withHandlers({
    openProfile: props => () => {
      alert('This feature is not available right now and our team is working on it! Please check back later')
      props.closeMenu()
    },
  }),
  withProps(props => ({ isMenuOpen: Boolean(props.anchor) })),
)(ProfileMenu)
