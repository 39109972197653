import React from 'react'
import { compose } from 'recompose'
import { firestore } from 'firebase'
import swal from 'sweetalert'
import { Typography, Divider, Grid, FormControl, Select, OutlinedInput, MenuItem, withStyles } from '@material-ui/core'
import TextField from '../../Common/TextField'
import { withFirebase } from '../../Firebase'
import { FullWidthPrimaryButton as Btn } from '../../Common/Button'

class AddVanMaster extends React.PureComponent {
  state = {
    vanTypes: [],
    registrationNumber: '',
    name: '',
    typeId: '',
    typeName: '',
  }

  componentDidMount() {
    this.getVanTypes()
  }

  showError = (title, message) => swal({
    title,
    text: message,
    icon: 'error',
  })

  saveMaster = () => {
    if (!this.state.registrationNumber.length) {
      return this.showError(
        'Van Registration Number Missing',
        'Looks like you forgot to input the Registration number for the service van',
      )
    }

    if (!this.state.name.length) {
      return this.showError(
        'Name for Service Van Missing',
        'Looks like you forgot to input the name of the Service Van',
      )
    }

    if (!this.state.typeName) {
      return this.showError(
        'Please choose the type of Service Van',
        'You need to choose the type of Service Van. Please choose and try again!',
      )
    }

    const { registrationNumber, name, typeId, typeName } = this.state
    const data = { registrationNumber, name, typeId, typeName }

    /** @type firestore.CollectionReference */
    const coll = this.props.firebase.firestore.collection('van_masters')
    coll.add(data).then(() => {
      this.setState({
        registrationNumber: '',
        name: '',
        typeId: '',
        typeName: '',
      }, () => {
        this.props.onChange()
      })
      swal({
        title: 'Done!',
        text: 'The service van master has been added successfully!',
        timer: 3000,
        buttons: false,
        icon: 'success',
      })
    }).catch(console.error)
  }

  getVanTypes = async () => {
    /** @type firestore.CollectionReference */
    const svTypeColl = this.props.firebase.firestore.collection('van_types')
    const svTypeDocs = await svTypeColl.get()
    const vanTypes = svTypeDocs.docs.map((doc) => {
      const { id } = doc
      const data = doc.data()
      return { id, name: data.name }
    })
    this.setState({ vanTypes })
  }

  setVanType = (e) => {
    this.setState(state => ({
      typeId: e.target.value,
      typeName: e.target.value ? state.vanTypes.filter(a => a.id === e.target.value)[0].name : '',
    }))
  }

  render() {
    return (
      <Grid container direction="column" sytle={{ paddingTop: 20 }}>
        <Typography variant="display1">
          Add a new Service Van Master
        </Typography>
        <Typography variant="subheading">
          Fill the below details to add a new Service Van Master
        </Typography>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <TextField
          id="vanNumber"
          label="Van Registration Number"
          placeholder="Registration Number of the Van"
          style={{ marginTop: 10, marginBottom: 10 }}
          value={this.state.registrationNumber}
          onChange={text => this.setState({ registrationNumber: text })} />
        <TextField
          id="name"
          label="Name of Service Van"
          placeholder="What would you like to name this Service Van"
          style={{ marginTop: 10, marginBottom: 10 }}
          value={this.state.name}
          onChange={text => this.setState({ name: text })} />
        <FormControl fullWidth className={this.props.classes.textField} variant="outlined">
          <Select
            displayEmpty
            fullWidth
            value={this.state.typeId}
            onChange={this.setVanType}
            input={<OutlinedInput />}>
            <MenuItem value="">
              Select a Van
            </MenuItem>
            {this.state.vanTypes.map(m => (
              <MenuItem key={m.id} value={m.id}>
                {m.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Btn onClick={this.saveMaster}>
          Add New Service Van Master
        </Btn>
      </Grid>
    )
  }
}

const styles = theme => ({
  select: {
    fontSize: 30,
  },
  textField: {
    display: 'flex',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: '#FAFAFA',
  },
})

export default compose(
  withStyles(styles),
  withFirebase,
)(AddVanMaster)
