import firebase from 'firebase'
import axios from 'axios'
import assert from 'assert'

export async function sendSMSUser(userId, text) {
  const userDoc = firebase.firestore().doc(`users/${userId}`)
  const user = (await userDoc.get()).data()
  const phoneNumber = user.phoneNumber.replace('+91', '')
  return sendSMS(phoneNumber, text)
}

export async function sendSMSMechanic(mechanicId, text) {
  const userDoc = firebase.firestore().doc(`mechanics/${mechanicId}`)
  const user = (await userDoc.get()).data()
  const phoneNumber = user.phone.replace('+91', '')
  return sendSMS(phoneNumber, text)
}

function sendSMS(phoneNumber, text) {
  assert(phoneNumber, 'Phone Number is required')
  assert(text, 'A text message to send is required')

  const BASEURI = 'http://Vtsms.co.in:8080/bulksms/bulksms'
  const params = {
    username: 'yoy-swandre',
    password: '123456',
    type: '0',
    dlr: '1',
    destination: `91${phoneNumber}`,
    message: text,
    source: 'EMSEVA',
  }

  return axios.get(BASEURI, { params })
}
