import React from 'react'
import { Typography, Button, Grid } from '@material-ui/core'

export default ({ title, rightComponent }) => (
  <div style={{ marginTop: 20, marginBottom: 20, backgroundColor: '#EAEAEA', display: 'block', overflow: 'auto' }}>
    <div style={{ padding: '10px 20px', float: 'left' }}>
      <Typography variant="subtitle1">
        {title}
      </Typography>
    </div>
    <div style={{ padding: '10px 20px', float: 'right' }}>
      {rightComponent}
    </div>
  </div>
)
