import { Typography } from '@material-ui/core'
import React from 'react'
import { compose } from 'recompose'
import swal from 'sweetalert'
import yellow from '@material-ui/core/colors/yellow'

import { firestore } from 'firebase'
import * as ROUTES from '../../constants/routes'
import { Login } from '../../layouts'
import { FullWidthPrimaryButton } from '../Common/Button'
import TextField from '../Common/TextField'
import { withFirebase } from '../Firebase'

class SignIn extends React.Component {
  state = {
    mobileNumber: '',
    requestedOTP: false,
    otp: '',
    processing: false,
    message: 'Sending OTP. Please wait...',
    error: null,
  }

  componentDidMount() {
    window.recaptchaVerifier = new this.props.firebase.app.auth.RecaptchaVerifier('recaptcha-container',
      {
        size: 'invisible',
        // other options
      })
  }

  requestOTP = async () => {
    if (this.state.mobileNumber && this.state.mobileNumber.length >= 10) {
      const _mobileNumber = this.state.mobileNumber
      const mobileNumber = _mobileNumber.startsWith('+') ? _mobileNumber : `+91${_mobileNumber}`
      const isUserAdmin = await this.checkIfUserAdmin(mobileNumber)
      if (isUserAdmin) {
        this.setState({ processing: true, message: 'Sending OTP. Please wait...' })
        const appVerifier = window.recaptchaVerifier
        await this.props.firebase.signInWithMobile(mobileNumber, appVerifier).catch(
          (err) => {
            console.error(err)
            this.setState({ error: err.message })
          },
        )
        this.setState({ requestedOTP: true, processing: false })
        swal({
          title: 'OTP Sent',
          text: `An OTP has been sent to ${mobileNumber}`,
          icon: 'success',
          button: false,
          timer: 2000,
        })
      } else {
        swal({
          title: 'Access Denied!',
          text: 'You do not have admin access to EMSeva. Please contact your EMSeva Administrator.',
          icon: 'error',
          button: false,
          timer: 3000,
        })
      }
    } else {
      swal({
        title: 'Oops',
        text: 'Invalid mobile number. Please check your mobile number and try again!',
        icon: 'error',
        button: false,
        timer: 3000,
      })
    }
  }

  confirmLogin = async () => {
    if (!this.state.otp || this.state.otp.length === 0) {
      return swal({
        title: 'Missing OTP',
        text: 'Missing OTP for your mobile number',
        icon: 'error',
        button: false,
        timer: 3000,
      })
    }

    this.setState({ processing: true, message: 'Validating your OTP...' })

    this.props.firebase.confirmMobileNumber(this.state.otp)
      .then((cred) => {
        this.props.history.push(ROUTES.LANDING)
      })
      .catch((err) => {
        this.setState({ processing: false })
        swal({
          title: 'Opps. Error Loggging In!',
          text: err.message,
          icon: 'error',
        })
      })
  }

  checkIfUserAdmin = async (mobileNumber) => {
    /** @type firestore.Query */
    const users = this.props.firebase.firestore.collection('users').where('phoneNumber', '==', mobileNumber)
    console.log('The users are', users)
    const foundUsers = await users.get()
    for (const index in foundUsers.docs) {
      const user = foundUsers.docs[index]
      const u = user.data()
      if (u.is_admin) return true
    }
    return false
  }

  render() {
    return (
      <div style={{ marginTop: 40 }}>
        <input id="recaptcha-container" type="button" onClick="this.onClick" style={{ visibility: 'hidden' }} />
        <Login text="Login to your account">
          {!this.state.requestedOTP && (
            <>
              <TextField
                id="mobile"
                label="Mobile Number"
                placeholder="Your EMSeva registered Mobile Number"
                value={this.state.mobileNumber}
                onChange={val => this.setState({ mobileNumber: val })} />
              <FullWidthPrimaryButton disabled={this.state.processing} onClick={this.requestOTP}>
                Request OTP
              </FullWidthPrimaryButton>
            </>
          )}
          {this.state.requestedOTP && (
            <>
              <Typography variant="headline" gutterBottom>
                An OTP has been sent to your mobile number
                {' '}
                {this.state.mobileNumber}
              </Typography>
              <TextField
                id="otp"
                label="OTP"
                placeholder="OTP Received on your mobile number"
                value={this.state.otp}
                onChange={val => this.setState({ otp: val })} />
              <FullWidthPrimaryButton disabled={this.state.processing} onClick={this.confirmLogin}>
                Login
              </FullWidthPrimaryButton>
            </>
          )}
          {this.state.processing && (
            <div style={{ marginTop: 10, marginBottom: 10, padding: 10, borderRadius: 7, backgroundColor: yellow[500] }}>
              <Typography variant="subheading">
                {this.state.message}
              </Typography>
            </div>
          )}
        </Login>
      </div>
    )
  }
}

export default compose(
  withFirebase,
)(SignIn)
