import React from 'react'
import { compose, withState, lifecycle } from 'recompose'
import { firestore } from 'firebase'
import { CircularProgress, Typography, Grid, Divider } from '@material-ui/core'
import { withFirebase } from '../Firebase'
import Breadcrumb from '../Common/Breadcrumb'
import Container from '../Common/Container'
import UserDetails from './userDetails'
import Bookings from './Bookings'

const ViewCustomer = ({ customerId, user, loading }) => (
  <>
    <Breadcrumb title={`Customer / View / ${customerId}`} />
    <Container>
      <Grid container lg={12} direction="column" spacing={48} alignItems="center">
        {loading && (
        <>
          <CircularProgress />
          <Typography variant="title">
            Getting User Profile...
          </Typography>
        </>
        )}
        {!loading && user && (
        <>
          <UserDetails user={user} />
          <div style={{ marginTop: 30 }}>
            <Bookings userId={customerId} />
          </div>
        </>
        )}
      </Grid>
    </Container>
  </>
)

export default compose(
  withState('customerId', '_setCustomerId', props => props.match.params.id),
  withFirebase,
  withState('user', 'setUser', null),
  withState('loading', 'setLoading', true),
  lifecycle({
    async componentDidMount() {
      /** @type firestore.CollectionReference */
      const user = this.props.firebase.firestore.collection('users')
      const userRef = user.doc(this.props.customerId)
      const userData = await userRef.get()
      console.log(userData.data())
      this.props.setUser(userData.data())
      this.props.setLoading(false)
    },
  }),
)(ViewCustomer)
