import React from 'react'
import MUIDataTable from 'mui-datatables'
import { firestore } from 'firebase'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { compose } from 'recompose'
import moment from 'moment'
import { withFirebase } from '../Firebase'
import NameFromID from './NameFromID'

class BookingsTable extends React.Component {
  state = {
    data: [],
    loading: true,
  }

  async componentDidMount() {
    const bookings = []
    /** @type firestore.CollectionReference */
    const bookingsColl = this.props.firebase.firestore.collection('bookings')
    let userBookingsQuery = bookingsColl
    if (this.props.query) {
      userBookingsQuery = bookingsColl.where(...this.props.query)
    }
    const _bookings = await userBookingsQuery.get()
    for (const index in _bookings.docs) {
      const booking = _bookings.docs[index]
      const bookingId = booking.id
      const data = booking.data()
      bookings.push([
        bookingId,
        data.user_id,
        data.services,
        data.date.seconds === 0 ? null : moment(new Date(data.date.seconds * 1000)).format('Do MMM YYYY hh:mm'),
        data.address,
        data.service_camp ? 'Service Camp' : 'Door Step',
        data.status ? 'Completed' : 'Pending',
      ])
    }
    this.setState({ data: bookings, loading: false })
  }

  getColumns = () => [
    'Booking ID',
    {
      name: 'Customer Name',
      options: {
        filter: true,
        sort: true,
        empty: false,
        customBodyRender: (value, meta, updateValue) => meta.rowData && meta.rowData[1] && (
          <NameFromID id={meta.rowData[1]} />
        ),
      },
    },
    'Services',
    'Scheduled At',
    'Address',
    'Booking Type',
    {
      name: 'Status',
      options: {
        filter: true,
        sort: true,
        empty: false,
        customBodyRender: (value, meta, updateValue) => (meta.rowData && meta.rowData[6] === 'Pending' ? (
          <img src="/static/images/icon-attention.png" alt="attention" width="18" />
        ) : (
          <img src="/static/images/icon-success.png" alt="success" width="18" />
        )),
      },
    },
  ]

  getOptions = () => ({
    filterType: 'dropdown',
    filter: true,
    responsive: 'stacked',
    selectableRows: 'none',
    ...this.props.options,
  })

  render() {
    if (this.state.loading) {
      return (
        <Grid container alignItems="center" justify="center" direction="column" style={{ margin: 30 }}>
          <CircularProgress color="primary" />
          <Typography variant="headline" style={{ padding: 20 }}>
            Loading Bookings...
          </Typography>
        </Grid>
      )
    }

    return (
      <MUIDataTable
        title={this.props.title || 'Bookings'}
        columns={this.getColumns()}
        options={this.getOptions()}
        data={this.state.data} />
    )
  }
}

export default compose(
  withFirebase,
)(BookingsTable)
