import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { compose, withState, withHandlers } from 'recompose'
import { firestore } from 'firebase'
import Breadcrumb from '../../Common/Breadcrumb'
import Container from '../../Common/Container'
import TextField from '../../Common/TextField'
import { withFirebase } from '../../Firebase'

const InventoryListComponent = ({ name, setName, quantity, setQuantity, nameError, quantityError, onSave, price, setPrice, gst, setGst, priceError, gstError, isFreezed }) => (
  <Grid item lg={4} md={12}>
    <Typography variant="headline">
      Add a new Part
    </Typography>
    <TextField
      error={nameError}
      value={name}
      onChange={setName}
      placeholder="Name of the Spare"
      label="Spare Name"
      id="spare-name" />
    <TextField
      error={quantityError}
      value={quantity}
      onChange={setQuantity}
      placeholder="Quantity of the Spares"
      label="Quantity"
      id="spare-quantity" />
    <TextField
      error={priceError}
      value={price}
      onChange={setPrice}
      placeholder="Total Price paid for the Spare Parts"
      label="Total Price"
      id="spare-price" />
    <TextField
      error={gstError}
      value={gst}
      onChange={setGst}
      placeholder="Total GST Paid"
      label="GST"
      id="spare-gst" />
    {isFreezed && (
      <Button fullWidth variant="raised" color="primary" onClick={onSave}>
          Add Spare
      </Button>
    ) || (
      <Typography variant="display1">
          Please freeze the inventory to add a new one!
      </Typography>
    )}
  </Grid>
)

export default compose(
  withFirebase,
  withState('name', 'setName', null),
  withState('nameError', 'setNameError', false),
  withState('quantity', 'setQuantity', 0),
  withState('quantityError', 'setQuantityError', false),
  withState('price', 'setPrice', null),
  withState('priceError', 'setPriceError', false),
  withState('gst', 'setGst', 0),
  withState('gstError', 'setGstError', false),
  withState('isUpdated', 'update', () => Date.now()),
  withHandlers({
    onSave: ({ name, quantity, price, gst, firebase, ...props }) => (e) => {
      if (!name || name.length === 0) {
        return props.setNameError(true)
      }
      props.setNameError(false)


      if (!quantity || quantity.length === 0 || isNaN(quantity) || Number(quantity) <= 0) {
        return props.setQuantityError(true)
      }
      props.setQuantityError(false)

      if (!price || price.length === 0 || isNaN(price) || Number(price) <= 0) {
        return props.setPriceError(true)
      }
      props.setPriceError(false)

      if (!gst || gst.length === 0 || isNaN(gst)) {
        return props.setGstError(true)
      }
      props.setGstError(false)

      props.setName(null)
      props.setQuantity(0)
      props.setPrice(null)
      props.setGst(0)

      /** @type firestore.CollectionReference */
      const sparesRef = firebase.firestore.collection('spares')
      sparesRef.add({
        name: name.trim(),
        quantity: Number(quantity),
        price: Number(price),
        gst: Number(gst),
        created_at: new Date(),
      })
        .then(() => props.update(Date.now()))
        .catch(err => console.error(err))
    },
  }),
)(InventoryListComponent)
