import React from 'react'
import { compose, withState, lifecycle } from 'recompose'
import { firestore } from 'firebase'
import { withFirebase } from '../Firebase'

const LocationFromId = ({ location }) => <span>{location ? location.geo.formatted_address : '...'}</span>

export default compose(
  withFirebase,
  withState('location', 'setLocation', (props) => {
    try {
      return JSON.parse(localStorage.getItem(props.id))
    } catch (err) {
      return false
    }
  }),
  lifecycle({
    async componentDidMount() {
      if (!this.props.location) {
        /** @type firestore.DocumentReference */
        const locationRef = this.props.firebase.firestore.doc(`locations/${this.props.id}`)
        const location = (await locationRef.get()).data()
        localStorage.setItem(this.props.id, JSON.stringify(location))
        this.props.setLocation(location)
      }
    },
  }),
)(LocationFromId)
