import React from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent, TextField, withStyles, Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { compose, withState, withHandlers, lifecycle, branch, renderNothing } from 'recompose'
import { firestore } from 'firebase'
import { withFirebase } from '../../Firebase'

const EditIconComponent = ({
  id, isEditing, onClose, editService, name, price,
  setName, setPrice, nameError, priceError, classes,
  onSave,
}) => (
  <>
    <IconButton key={`${id}-edit-button`} onClick={editService}>
      <EditIcon />
    </IconButton>
    <Dialog fullWidth open={isEditing} onClose={onClose} maxWidth="md">
      <DialogTitle>Edit Service</DialogTitle>
      <DialogContent>
        <TextField
          error={nameError}
          id="name"
          label="Name"
          placeholder="The name of the Service"
          className={classes.textField}
          value={name}
          onChange={e => setName(e.currentTarget.value)}
          margin="normal"
          variant="outlined" />
        <TextField
          error={priceError}
          id="price"
          label="Price"
          placeholder="Price for the service"
          className={classes.textField}
          value={price}
          onChange={e => setPrice(e.currentTarget.value)}
          margin="normal"
          variant="outlined" />
        <Button fullWidth variant="raised" color="primary" size="large" className={classes.button} onClick={onSave}>
        Update Service
        </Button>
        <Button fullWidth variant="flat" color="primary" size="large" className={classes.button} onClick={onClose}>
        Cancel
        </Button>
      </DialogContent>
    </Dialog>
  </>
)

const styles = theme => ({
  textField: {
    display: 'flex',
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: '#FAFAFA',
  },
  button: {
    marginTop: theme.spacing.unit,
  },
})

export default compose(
  withFirebase,
  withStyles(styles),
  withState('isEditing', 'setIsEditing', false),
  withState('name', 'setName', props => props.data.name),
  withState('price', 'setPrice', props => props.data.price),
  withState('nameError', 'setNameError', false),
  withState('priceError', 'setPriceError', false),
  withHandlers({
    showDialog: props => () => {
      props.setIsEditing(true)
      props.setName(props.data.name)
      props.setPrice(props.data.price)
    },
  }),
  withHandlers({
    editService: props => () => {
      props.showDialog()
    },
    onClose: props => () => props.setIsEditing(false),
  }),
  withHandlers({
    onSave: props => () => {
      const { name, price } = props

      if (!name || name.length === 0) {
        return props.setNameError(true)
      }
      props.setNameError(false)


      if (!price || price.length === 0 || isNaN(price)) {
        return props.setPriceError(true)
      }
      props.setPriceError(false)


      /** @type firestore.CollectionReference */
      const mechanicsRef = props.firebase.firestore.collection('available_services')
      mechanicsRef.doc(props.id).set({ name, price }, { merge: true }).then(() => {
        props.setLastUpdated(Date.now())
        props.onClose()
      }).catch((err) => {
        console.error(err)
      })
    },
  }),
)(EditIconComponent)
