import React from 'react'
import { Grid, Typography, Paper, Table, TableHead, TableCell, TableRow, TableBody, TableFooter, Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import * as ROUTES from '../../constants/routes'
import NameFromID from '../Common/NameFromID'
import { withFirebase } from '../Firebase'

const PendingComplaints = ({ viewComplaints, complaints, viewComplaint }) => (
  <Grid item lg={4} sm={12} spacing={24}>
    <Paper>
      <Grid container direction="row" justify="space-between" style={{ padding: 20 }}>
        <Grid item>
          <Typography variant="headline">
            Pending Complaints
          </Typography>
          <Typography variant="subheading">
            Issues that needs to be resolved
          </Typography>
        </Grid>
        <Typography variant="display2" color="textPrimary">
          {complaints.length}
        </Typography>
      </Grid>
      <Table>
        <TableHead style={{ backgroundColor: '#EAEAEA' }}>
          <TableRow>
            <TableCell>
              <Typography variant="body1">
                Name
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {complaints.map(c => (
            <TableRow>
              <TableCell>
                <Typography variant="body1">
                  <NameFromID id={c.userId} />
                </Typography>
              </TableCell>
              <TableCell>
                <Button onClick={() => viewComplaint(c.id)}>View</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button fullWidth variant="contained" color="primary" size="large" onClick={viewComplaints}>
        View all Complaints
      </Button>
    </Paper>
  </Grid>
)

export default compose(
  withRouter,
  withFirebase,
  withState('complaints', 'setComplaints', []),
  withHandlers({
    viewComplaints: props => () => {
      props.history.push(ROUTES.COMPLAINTS_LIST)
    },
    viewComplaint: props => (id) => {
      props.history.push(`/complaint/${id}`)
    },
    getComplaints: props => async () => {
      /** @type firestore.CollectionReference */
      const complaintsColl = props.firebase.firestore.collection('complaints')
      const complaintDocs = await complaintsColl.get()
      const complaints = []
      for (const index in complaintDocs.docs) {
        const _c = complaintDocs.docs[index]
        const complaintId = _c.id
        const complaint = _c.data()
        complaints.push({
          id: complaintId,
          userId: complaint.user_id,
        })
      }
      props.setComplaints(complaints)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getComplaints()
    },
  }),
)(PendingComplaints)
