import React from 'react'
import { Grid, Typography, Paper, Table, TableHead, TableCell, TableRow, TableBody, TableFooter, Button } from '@material-ui/core'
import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { firestore } from 'firebase'
import moment from 'moment'
import uniq from 'lodash.uniq'
import { withFirebase } from '../Firebase'
import NameFromID from '../Common/NameFromID'
import * as ROUTES from '../../constants/routes'

const ServicesScheduled = ({ totalBookings, bookings, viewBookings }) => (
  <Grid item lg={8} sm={12} spacing={24}>
    <Paper>
      <Grid container direction="row" justify="space-between" style={{ padding: 20 }}>
        <Grid item>
          <Typography variant="headline">
            Bookings Scheduled Today
          </Typography>
          <Typography variant="subheading">
            Below are the bookings that are scheduled today
          </Typography>
        </Grid>
        <Typography variant="display2" color="textPrimary">
          {totalBookings}
        </Typography>
      </Grid>
      <Table>
        <TableHead style={{ backgroundColor: '#EAEAEA' }}>
          <TableRow>
            <TableCell><Typography variant="subheading">Name</Typography></TableCell>
            <TableCell><Typography variant="subheading">Location</Typography></TableCell>
            <TableCell><Typography variant="subheading">Vehicle</Typography></TableCell>
            <TableCell><Typography variant="subheading">Status</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings && bookings.map(booking => (
            <TableRow key={booking.date.seconds}>
              <TableCell>
                <Typography variant="body1">
                  <NameFromID id={booking.user_id} />
                </Typography>
              </TableCell>
              <TableCell><Typography variant="body1">{booking.address}</Typography></TableCell>
              <TableCell><Typography variant="body1">{booking.vehicle || ''}</Typography></TableCell>
              <TableCell>
                {
                  !booking.status
                    ? <img src="/static/images/icon-attention.png" alt="attention" width="18" />
                    : <img src="/static/images/icon-success.png" alt="success" width="18" />
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button fullWidth variant="contained" color="primary" size="large" onClick={viewBookings}>
        Manage Bookings
      </Button>
    </Paper>
  </Grid>
)

export default compose(
  withFirebase,
  withState('totalBookings', 'setTotalBookings', () => localStorage.getItem('today-bookings-count')),
  withState('bookings', 'setBookings', () => localStorage.getItem('today-bookings') && JSON.parse(localStorage.getItem('today-bookings'))),
  lifecycle({
    async componentDidMount() {
      /** @type firestore.CollectionReference */
      const bookingsRef = this.props.firebase.firestore.collection('bookings')
      const allBookings = bookingsRef.where('date', '>=', moment().startOf('day').toDate())
      const _bookings = await allBookings.orderBy('date').get()
      const bookings = _bookings.docs.map(b => b.data())
      localStorage.setItem('today-bookings-count', bookings.length)
      this.props.setTotalBookings(bookings.length)
      localStorage.setItem('today-bookings', JSON.stringify(uniq(bookings, 'date')))
      this.props.setBookings(bookings)
    },
  }),
  withHandlers({
    viewBookings: props => () => {
      props.history.push(ROUTES.BOOKING_LIST)
    },
  }),
)(ServicesScheduled)
