import React from 'react'
import { Grid } from '@material-ui/core'
import { compose } from 'recompose'
import { firestore } from 'firebase'
import MUIDataTable from 'mui-datatables'

import Breadcrumb from '../../Common/Breadcrumb'
import Container from '../../Common/Container'
import { withFirebase } from '../../Firebase'
import AddVanMaster from './add'

class VanMaster extends React.PureComponent {
  state = {
    data: [],
  }

  componentDidMount () {
    this.getMasterData()
  }

  getMasterData = async () => {
    /** @type firestore.CollectionReference */
    const masterColl = this.props.firebase.firestore.collection('van_masters')
    const masterDocs = await masterColl.get()
    const data = masterDocs.docs.map((doc) => {
      const { id } = doc
      const docData = doc.data()
      return [
        id,
        docData.registrationNumber,
        docData.name,
        docData.typeName,
      ]
    })
    this.setState({ data })
  }

  getOptions = {
    filterType: 'dropdown',
    filter: true,
    responsive: 'stacked',
    selectableRows: 'none',
    textLabels: { body: { noMatch: 'No Service Vans avaialble. Add a new one!' } },
  }

  render () {
    return (
      <>
        <Breadcrumb title="Service Vans / Master" />
        <Container>
          <Grid container spacing={40}>
            <Grid item md={8}>
              <MUIDataTable
                title="Service Van Master"
                columns={['ID', 'Registration Number', 'Name', 'Van Type']}
                options={this.getOptions}
                data={this.state.data} />
            </Grid>
            <Grid item md={4}>
              <AddVanMaster onChange={this.getMasterData} />
            </Grid>
          </Grid>
        </Container>
      </>
    )
  }
}

export default compose(
  withFirebase,
)(VanMaster)
