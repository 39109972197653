import React from 'react'
import { compose, withState, lifecycle } from 'recompose'
import { firestore } from 'firebase'
import { withFirebase } from '../Firebase'

const BrandFromId = ({ brand }) => <span>{brand ? brand.name : '...'}</span>

export default compose(
  withFirebase,
  withState('brand', 'setBrand', props => typeof id === 'string' && localStorage.getItem(props.id) && JSON.parse(localStorage.getItem(props.id))),
  lifecycle({
    async componentDidMount() {
      if (!this.props.brand) {
        if (typeof this.props.id === 'string') {
          /** @type firestore.DocumentReference */
          const brandRef = this.props.firebase.firestore.doc(`brands/${this.props.id}`)
          const brand = (await brandRef.get()).data()
          localStorage.setItem(this.props.id, JSON.stringify(brand))
          this.props.setBrand(brand)
        } else {
          /** @type firestore.CollectionReference */
          const BrandsCollRef = this.props.firebase.firestore.collection('brands')
          const brandDocs = await BrandsCollRef.get()
          const brands = []
          for (const b of brandDocs.docs) {
            if (this.props.id.includes(b.id)) {
              brands.push({ id: b.id, ...b.data() })
            }
          }
          this.props.setBrand({
            name: brands.map(b => b.name).join(', '),
          })
        }
      }
    },
  }),
)(BrandFromId)
