import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose, withHandlers, withState, withStateHandlers } from 'recompose'
import { TextField, withStyles, Button, SnackbarContent, Snackbar } from '@material-ui/core'
import { firestore } from 'firebase'
import { green, amber } from '@material-ui/core/colors'
import { withFirebase } from '../../Firebase'
import * as ROUTES from '../../../constants/routes'
import Category from './category'
import SubCategory from './Subcategory'
import Brand from './brand'
import LocationsDropdown from '../../Common/LocationsDropdown'
import { sendSMSMechanic } from '../../../helpers/sendSMS'

const Form = ({
  classes,
  name,
  phone,
  alternatePhone,
  email,
  address,
  setName,
  setPhone,
  setAlternatePhone,
  setEmail,
  setAddress,
  isOpen,
  status,
  text,
  handleClose,
  onSave,
  setCategory,
  category,
  subCategory,
  setSubCategory,
  brand,
  setBrand,
  location,
  setLocation,
}) => (
  <div className={classes.container}>
    <form onSubmit={onSave}>
      <TextField
        id="name"
        label="Name"
        placeholder="The name of the Mechanic"
        className={classes.textField}
        value={name}
        onChange={e => setName(e.currentTarget.value)}
        margin="normal"
        variant="outlined" />
      <TextField
        id="phone"
        label="Phone Number"
        placeholder="The primary contact number of the mechanic where he could be contacted upon"
        className={classes.textField}
        value={phone}
        onChange={e => setPhone(e.currentTarget.value)}
        margin="normal"
        variant="outlined" />
      <TextField
        id="alternatephone"
        label="Alternate Phone Number"
        placeholder="An alternate number in case the primary contact number is not reachable"
        className={classes.textField}
        value={alternatePhone}
        onChange={e => setAlternatePhone(e.currentTarget.value)}
        margin="normal"
        variant="outlined" />
      <TextField
        id="email"
        label="Email Address (Optional)"
        placeholder="An email address for the mechanic"
        className={classes.textField}
        value={email}
        onChange={e => setEmail(e.currentTarget.value)}
        margin="normal"
        variant="outlined" />
      <TextField
        multiline
        rows={3}
        rowsMax={10}
        id="address"
        label="Address"
        placeholder="The residentail address of the mechanic"
        className={classes.textField}
        value={address}
        onChange={e => setAddress(e.currentTarget.value)}
        margin="normal"
        variant="outlined" />
      <Brand value={brand} onChange={setBrand} className={classes.textField} />
      <LocationsDropdown value={location} onChange={setLocation} />
      <Button
        variant="raised"
        color="primary"
        size="large"
        className={classes.button}
        onClick={onSave}>
        Add New Mechanic
      </Button>
    </form>
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isOpen}
      autoHideDuration="6000"
      onClose={handleClose}
      ContentProps={{ 'aria-describedby': 'add-mechanic' }}>
      <SnackbarContent
        className={classes[status]}
        aria-describedby="client-snackbar"
        message={(
          <span id="client-snackbar" className={classes.message}>
            {text}
          </span>
        )} />
    </Snackbar>
  </div>
)

const styles = theme => ({
  container: {
    marginTop: 20,
  },
  textField: {
    display: 'flex',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: '#FAFAFA',
  },
  button: {
    marginTop: theme.spacing.unit * 2,
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
})

export default compose(
  withStyles(styles),
  withFirebase,
  withRouter,
  withState('name', 'setName', null),
  withState('phone', 'setPhone', null),
  withState('alternatePhone', 'setAlternatePhone', null),
  withState('email', 'setEmail', null),
  withState('address', 'setAddress', null),
  withState('isOpen', 'setIsOpen', false),
  withState('text', 'setText', null),
  withState('status', 'setStatus', null),
  withState('brand', 'setBrand', []),
  withState('location', 'setLocation', ''),
  withHandlers({
    handleClose: props => (e, reason) => {
      props.setIsOpen(false)
    },
  }),
  withHandlers({
    openSnackbar: props => () => props.setIsOpen(true),
    closeSnackbar: props => () => props.handleClose(),
  }),
  withHandlers({
    onSave: props => e => {
      e.preventDefault()
      const {
        name,
        address,
        phone,
        alternatePhone,
        email,
        category,
        subCategory,
        brand,
        location,
      } = props

      if (!name) {
        props.setText('Name is required!')
        props.setStatus('warning')
        props.setIsOpen(true)
        return false
      }

      if (!phone) {
        props.setText('Phone Number is required!')
        props.setStatus('warning')
        props.setIsOpen(true)
        return false
      }

      if (!address) {
        props.setText('Address is required!')
        props.setStatus('warning')
        props.setIsOpen(true)
        return false
      }

      if (brand.length === 0) {
        props.setText('Brand is required')
        props.setStatus('warning')
        props.setIsOpen(true)
        return false
      }

      if (location.length === 0) {
        props.setText('Brand is required')
        props.setStatus('warning')
        props.setIsOpen(true)
        return false
      }

      /** @type firestore.CollectionReference */
      const mechanicsRef = props.firebase.firestore.collection('mechanics')
      mechanicsRef
        .add({
          name,
          phone,
          alternatePhone,
          email,
          address,
          brand,
          location,
        })
        .then(doc => {
          props.setText('Mechanic has been added successfully!')
          props.setStatus('success')
          props.setIsOpen(true)
          localStorage.removeItem('mechanics')
          setTimeout(() => props.history.push(ROUTES.LIST_MECHANIC), 1000)
          return sendSMSMechanic(
            doc.id,
            `Welcome to EMSeva. You have been added as a mechanic. Please download EMSeva Mechanic App from Play Store and login with your mobile number: ${phone}`,
          )
        })
        .catch(err => {
          props.setText('Error creating a mechanic. Please try again!')
          props.setStatus('error')
          props.setIsOpen(true)
          console.error(err)
        })
    },
  }),
)(Form)
