import React from 'react'
import { Paper, Grid } from '@material-ui/core'
import { compose, withState, lifecycle } from 'recompose'
import { firestore } from 'firebase'
import NumberItem from './NumberItem'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

const NumberGridComponent = ({ users, bookings, mechanics, serviceVans, completedBookings }) => (
  <Paper>
    <Grid container lg={12} direction="row" spacing={48} alignItems="center">
      <NumberItem value={mechanics} text="Total Mechanics" href={ROUTES.LIST_MECHANIC} />
      <NumberItem value={users} text="Registered Customers" href={ROUTES.CUSTOMERS} />
      <NumberItem value={serviceVans} text="Service Vans" href={ROUTES.MANAGE_SERVICE_VANS} />
      <NumberItem value={localStorage.getItem('today-bookings-count')} text="Services Today" href={ROUTES.BOOKING_LIST} />
      <NumberItem value={bookings} text="Bookings" href={ROUTES.BOOKING_LIST} />
      <NumberItem value={completedBookings} text="Completed Bookings" href={ROUTES.BOOKING_LIST} />
    </Grid>
  </Paper>
)

export default compose(
  withFirebase,
  withState('users', 'setUsers', () => localStorage.getItem('users-count')),
  withState('bookings', 'setBookings', () => localStorage.getItem('bookings-count')),
  withState('mechanics', 'setMechanics', () => localStorage.getItem('mechanics-count')),
  withState('completedBookings', 'setCompletedBookings', () => localStorage.getItem('bookings-complete-count')),
  withState('serviceVans', 'setServiceVans', () => localStorage.getItem('service-vans-count')),
  lifecycle({
    async componentDidMount() {
      /** @type firestore.CollectionReference */
      const usersRef = this.props.firebase.firestore.collection('users')
      const users = (await usersRef.get()).docs.length
      localStorage.setItem('users-count', users)
      this.props.setUsers(users)

      /** @type firestore.CollectionReference */
      const bookingsRef = this.props.firebase.firestore.collection('bookings')
      const bookings = (await bookingsRef.get()).docs.length
      localStorage.setItem('bookings-count', bookings)
      this.props.setBookings(bookings)
      const completedBookings = (await bookingsRef.where('status', '==', true).get()).docs.length
      localStorage.setItem('bookings-complete-count', completedBookings)
      this.props.setCompletedBookings(completedBookings)

      /** @type firestore.CollectionReference */
      const mechanicsRef = this.props.firebase.firestore.collection('mechanics')
      const mechanics = (await mechanicsRef.get()).docs.length
      localStorage.setItem('mechanics-count', mechanics)
      this.props.setMechanics(mechanics)

      /** @type firestore.CollectionReference */
      const serviceVansRef = this.props.firebase.firestore.collection('service_vans')
      const serviceVans = (await serviceVansRef.get()).docs.length
      localStorage.setItem('service-vans-count', serviceVans)
      this.props.setServiceVans(serviceVans)
    },
  }),
)(NumberGridComponent)
