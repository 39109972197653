/* eslint-disable no-param-reassign */
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { firestore } from 'firebase'
import moment from 'moment'
import React, { Component } from 'react'
import Chart from 'react-highcharts'
import { withFirebase } from '../Firebase'

class DateChartComponent extends Component {
  state = {
    loading: true,
    data: [],
  }

  componentDidMount() {
    this.getBookingsData()
  }

  getBookingsData = async () => {
    /** @type firestore.CollectionReference */
    const ref = this.props.firebase.firestore.collection(this.props.collection)
    let docs = await ref.get()
    if (this.props.where) {
      docs = docs.where(...this.props.where)
    }

    const bookings = docs.docs
      .map(b => new Date(b.data()[this.props.dateField].seconds * 1000))
      .map(b => moment(b).startOf('day').toDate())
      .reduce((prev, next) => ({ ...prev, [next]: (prev[next] || 0) + 1 }), {})

    this.setState({ data: bookings, loading: false })
  }

  render() {
    if (this.state.loading) {
      return (
        <Grid container alignItems="center" justify="center" direction="column" style={{ margin: 30 }}>
          <CircularProgress color="primary" />
          <Typography variant="headline" style={{ padding: 20 }}>
            {this.props.loadingText || 'Loading...'}
          </Typography>
        </Grid>
      )
    }

    const sortedDates = Object.keys(this.state.data).sort((a, b) => new Date(a) - new Date(b))
    const config = {
      ...this.props.options,
      title: {
        text: this.props.title,
      },
      xAxis: {
        categories: sortedDates.map(d => moment(new Date(d)).format('Do MMM')),
      },
      series: [{
        name: this.props.seriesName,
        data: sortedDates.map(index => this.state.data[index]),
      }],
      credits: false,
    }

    return (
      <Chart config={config} />
    )
  }
}

export default withFirebase(DateChartComponent)
