import React from 'react'
import { Typography } from '@material-ui/core'

export default () => (
  <div style={{ padding: 20 }}>
    <Typography variant="headline" style={{ marginBottom: 20 }}>
      Important Information
    </Typography>
    <div style={{ margin: '20px 0px' }}>
      <Typography variant="title" gutterBottom>
        Name
      </Typography>
      <Typography variant="body1" gutterBottom>
        The name of the Service you intent to add to your checklist
      </Typography>
    </div>
    <div style={{ margin: '20px 0px' }}>
      <Typography variant="title" gutterBottom>
        Price
      </Typography>
      <Typography variant="body1" gutterBottom>
        The price of the Service you intent to add to your checklist
      </Typography>
    </div>
  </div>
)
