import React from 'react'
import { Select, MenuItem, withStyles, Grid, CircularProgress, Typography, FormControl, OutlinedInput } from '@material-ui/core'
import { compose } from 'recompose'
import { firestore } from 'firebase'
import { withFirebase } from '../Firebase'

class ServiceVansDropdown extends React.Component {
  state = {
    currentValue: this.props.value,
    serviceVans: [],
    loading: true,
  }

  async componentDidMount () {
    const localVanMasters = localStorage.getItem('van_masters')
    if (localVanMasters) {
      this.setState({ serviceVans: JSON.parse(localVanMasters), loading: false })
    } else {
      /** @type firestore.CollectionReference */
      const locationsColl = this.props.firebase.firestore.collection('van_masters')
      const serviceVans = await locationsColl.get()
      const finallocations = serviceVans.docs.map(d => ({ id: d.id, ...d.data() }))
      this.setState({ serviceVans: finallocations, loading: false })
      localStorage.setItem('serviceVans', JSON.stringify(finallocations))
    }
  }

  setValue = (e) => {
    this.setState({ currentValue: e.target.value })
    const val = e.target.value
    if (!val || !val.length) {
      return false
    }

    return this.props.onChange(this.state.serviceVans.filter(s => s.id === val)[0])
  }

  render() {
    if (this.state.loading) {
      return (
        <Grid container alignItems="center" justify="center" direction="column" style={{ margin: 30 }}>
          <CircularProgress color="primary" />
          <Typography variant="headline" style={{ padding: 20 }}>
            Loading Service Vans...
          </Typography>
        </Grid>
      )
    }

    return (
      <FormControl fullWidth className={this.props.classes.textField} variant="outlined">
        <Select
          displayEmpty
          fullWidth
          value={this.state.currentValue}
          onChange={this.setValue}
          input={<OutlinedInput />}>
          <MenuItem value="">
            Select a Service Van
          </MenuItem>
          {this.state.serviceVans.map(m => (
            <MenuItem key={m.id} value={m.id}>
              {`${m.name} (${m.registrationNumber})`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}

const styles = theme => ({
  select: {
    fontSize: 30,
  },
  textField: {
    display: 'flex',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: '#FAFAFA',
  },
})

export default compose(
  withStyles(styles),
  withFirebase,
)(ServiceVansDropdown)
