/* eslint-disable guard-for-in */
import React from 'react'
import MUIDataTable from 'mui-datatables'
import { firestore } from 'firebase'
import { Grid, CircularProgress, Typography, IconButton } from '@material-ui/core'
import moment from 'moment'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import { compose } from 'recompose'
import { withFirebase } from '../Firebase'
import NameFromID from '../Common/NameFromID'
import AssignToMechanic from './AssignToMechanic'

class PendingAssignments extends React.Component {
  state = {
    loading: true,
    data: [],
  }

  componentDidMount() {
    this.getPendingBookings()
  }

  getPendingBookings = async () => {
    const bookings = []
    /** @type firestore.CollectionReference */
    const bookingsColl = this.props.firebase.firestore.collection('bookings')
    const _bookings = await bookingsColl.get()
    // eslint-disable-next-line no-restricted-syntax
    for (const index in _bookings.docs) {
      const booking = _bookings.docs[index]
      const bookingId = booking.id
      const data = booking.data()
      if (!data.status && !data.mechanic) {
        bookings.push([
          bookingId,
          data.user_id,
          data.services,
          data.date.seconds === 0 ? null : moment(new Date(data.date.seconds * 1000)).format('Do MMM YYYY hh:mm'),
          data.address,
          data.service_camp ? 'Service Camp' : 'Door Step',
          data.status ? 'Completed' : 'Pending',
          data,
        ])
      }
    }
    this.setState({ data: bookings, loading: false })
  }

  onAssign = () => {
    this.setState({ loading: true }, () => {
      this.getPendingBookings()
    })
  }

  getColumns = () => [
    'Booking ID',
    {
      name: 'Customer Name',
      options: {
        filter: true,
        sort: true,
        empty: false,
        customBodyRender: (value, meta, updateValue) => meta.rowData && meta.rowData[1] && (
          <NameFromID id={meta.rowData[1]} />
        ),
      },
    },
    'Services',
    'Scheduled At',
    'Address',
    'Booking Type',
    {
      name: 'Assign to Mechanic',
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value, meta, updateValue) => meta.rowData && meta.rowData[0] && (
          <AssignToMechanic id={meta.rowData[0]} data={meta.rowData[7]} onAssign={this.onAssign} userId={meta.rowData[1]} />
        ),
      },
    },
  ]

  getOptions = () => ({
    filterType: 'dropdown',
    filter: true,
    responsive: 'stacked',
    selectableRows: 'none',
    rowsPerPage: 5,
    ...this.props.options,
  })

  render() {
    if (this.state.loading) {
      return (
        <Grid container alignItems="center" justify="center" direction="column" style={{ margin: 30 }}>
          <CircularProgress color="primary" />
          <Typography variant="headline" style={{ padding: 20 }}>
            Loading Pending Bookings...
          </Typography>
        </Grid>
      )
    }

    if (this.state.data.length) {
      return (
        <div style={{ marginTop: 30 }}>
          <MUIDataTable
            title="Pending Bookings for Assignment"
            columns={this.getColumns()}
            options={this.getOptions()}
            data={this.state.data} />
        </div>
      )
    }
    return null
  }
}

export default compose(
  withFirebase,
)(PendingAssignments)
