import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import initReactFastclick from 'react-fastclick'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Navigation from '../Navigation'
import LandingPage from '../Landing'
import SignUpPage from '../SignUp'
import SignInPage from '../SignIn'
import PasswordForgetPage from '../PasswordForget'
import HomePage from '../Home'
import AccountPage from '../Account'
import AdminPage from '../Admin'
import CustomersPage from '../Customers'
import CustomerSearchPage from '../Customers/SearchResults'
import ViewCustomerPage from '../Customers/view'
import AddMechanicPage from '../Mechanics/add'
import ListMechanicsPage from '../Mechanics/list'
import ChecklistServicesPage from '../Checklist/Services'
import BookingsListPage from '../Bookings/list'
import ListMembershipPage from '../Memberships/List'
import AddMembershipPage from '../Memberships/Add'
import InventoryListPage from '../Inventory/List'
import NotificationsListPage from '../Notifications/list'
import NotificationsPublishPage from '../Notifications/publish'
import ServiceVansListPage from '../ServiceVans/list'
import LocationsPage from '../Locations'
import ComplaintsListPage from '../Complaints/list'
import ViewComplaintPage from '../Complaints/view'
import VanMaster from '../ServiceVans/master'

import * as ROUTES from '../../constants/routes'
import { withAuthentication } from '../Session'
import defaultTheme from '../../theme'

const theme = createMuiTheme(defaultTheme)
initReactFastclick()

const App = withAuthentication(({ firebase }) => (
  <MuiThemeProvider theme={theme}>
    <Router>
      <Grid spacing={24}>
        <Navigation />
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route
          path={ROUTES.PASSWORD_FORGET}
          component={PasswordForgetPage} />
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route exact path={ROUTES.CUSTOMERS} component={CustomersPage} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />
        <Route path={ROUTES.SEARCH_CUSTOMER} component={CustomerSearchPage} />
        <Route path={ROUTES.VIEW_CUSTOMER} component={ViewCustomerPage} />
        <Route exact path={ROUTES.ADD_MECHANIC} component={AddMechanicPage} />
        <Route exact path={ROUTES.LIST_MECHANIC} component={ListMechanicsPage} />
        <Route exact path={ROUTES.SERVICES_CHECKLIST} component={ChecklistServicesPage} />
        <Route exact path={ROUTES.BOOKING_LIST} component={BookingsListPage} />
        <Route exact path={ROUTES.MEMBERSHIP_VIEW} component={ListMembershipPage} />
        <Route exact path={ROUTES.MEMBERSHIP_ADD} component={AddMembershipPage} />
        <Route path={ROUTES.INVENTORY_MANAGE} component={InventoryListPage} />
        <Route exact path={ROUTES.NOTIFICATIONS_LIST} component={NotificationsListPage} />
        <Route exact path={ROUTES.NOTIFICATIONS_PUBLISH} component={NotificationsPublishPage} />
        <Route exact path={ROUTES.MANAGE_SERVICE_VANS} component={ServiceVansListPage} />
        <Route exact path={ROUTES.MANAGE_LOCATIONS} component={LocationsPage} />
        <Route exact path={ROUTES.COMPLAINTS_LIST} component={ComplaintsListPage} />
        <Route exact path={ROUTES.VIEW_COMPLAINT} component={ViewComplaintPage} />
        <Route exact path={ROUTES.SERVICE_VAN_MASTER} component={VanMaster} />
      </Grid>
    </Router>
  </MuiThemeProvider>
))

const PrivateRoute = withAuthentication(({ component: Component, firebase, ...rest }) => (
  <Route
    {...rest}
    render={props => (firebase.auth.currentUser ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: ROUTES.SIGN_IN,
          state: { from: props.location },
        }} />
    ))
    } />
))

PrivateRoute.displayName = 'PrivateRoute'

export default withAuthentication(App)
