export const LANDING = '/'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const HOME = '/home'
export const ACCOUNT = '/account'
export const ADMIN = '/admin'
export const PASSWORD_FORGET = '/password-forget'
export const CUSTOMERS = '/customers'
export const SEARCH_CUSTOMER = '/customers/search/:type/:search'
export const VIEW_CUSTOMER = '/customer/:id'
export const ADD_MECHANIC = '/mechanics/new'
export const LIST_MECHANIC = '/mechanics'
export const SERVICES_CHECKLIST = '/checklist/services'
export const BOOKING_LIST = '/bookings'
export const MEMBERSHIP_VIEW = '/memberships'
export const MEMBERSHIP_ADD = '/memberships/add'
export const INVENTORY_MANAGE = '/inventory'
export const NOTIFICATIONS_LIST = '/notifications'
export const NOTIFICATIONS_PUBLISH = '/notifications/publish'
export const MANAGE_SERVICE_VANS = '/servicevans'
export const MANAGE_LOCATIONS = '/locations'
export const COMPLAINTS_LIST = '/complaints'
export const VIEW_COMPLAINT = '/complaint/:id'
export const SERVICE_VAN_MASTER = '/vans/master'