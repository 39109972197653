import React from 'react'
import { compose } from 'recompose'
import {
  IconButton,
  Slide,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Dialog,
} from '@material-ui/core'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import { firestore } from 'firebase'
import MechanicsDropdown from '../Common/MechanicsDropdown'
import { withFirebase } from '../Firebase'
import { sendSMSUser, sendSMSMechanic } from '../../helpers/sendSMS'

class AssignToMechanic extends React.Component {
  state = {
    dialogOpen: false,
    selectedMechanic: null,
  }

  closeDialog = () => this.setState({ dialogOpen: false })

  selectMechanic = id => this.setState({ selectedMechanic: id })

  assignMechanic = () => {
    this.setState({ dialogOpen: true })
  }

  confirmAssignMechanic = () => {
    const bookingId = this.props.id
    const mechanicId = this.state.selectedMechanic
    /** @type firestore.DocumentReference */
    const bookingRef = this.props.firebase.firestore.doc(`bookings/${bookingId}`)
    bookingRef
      .set(
        {
          mechanic: this.state.selectedMechanic,
          assign_date: new Date(),
        },
        {
          merge: true,
        },
      )
      .then(() => this.props.onAssign())
      .then(() =>
        Promise.all([
          sendSMSUser(
            this.props.userId,
            'Hi, a Mechanic has been assigned for your booking. You would be contacted shortly!',
          ),
          sendSMSMechanic(
            mechanicId,
            'You have been assigned a booking. Please login to your mechanic app to view details!',
          ),
        ]),
      )
      .catch(err => console.error(err))
    this.closeDialog()
  }

  render() {
    return (
      <>
        <IconButton key={this.props.id} onClick={this.assignMechanic}>
          <RecordVoiceOverIcon color="primary" />
        </IconButton>
        {this.state.dialogOpen && (
          <Dialog
            fullWidth
            open={this.state.dialogOpen}
            TransitionComponent={props => <Slide direction="up" {...props} />}
            onClose={this.closeDialog}>
            <DialogTitle>Select Mechanic to assign</DialogTitle>
            <DialogContent>
              <DialogContentText>Assign booking to a mechanic</DialogContentText>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <MechanicsDropdown
                  value={this.state.selectedMechanic}
                  onChange={this.selectMechanic} />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} color="primary">
                No, Cancel
              </Button>
              <Button onClick={this.confirmAssignMechanic} color="primary">
                Yes, Assign to Mechanic
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    )
  }
}

export default compose(withFirebase)(AssignToMechanic)
