import React from 'react'
import { Snackbar, SnackbarContent, withStyles, Typography } from '@material-ui/core'
import { green, amber } from '@material-ui/core/colors'

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
})

export default withStyles(styles)(({ classes, text, isOpen, handleClose, status }) => (
  <Snackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    open={isOpen}
    autoHideDuration="6000"
    onClose={handleClose}
    ContentProps={{ 'aria-describedby': 'add-mechanic' }}>
    <SnackbarContent
      className={classes[status]}
      aria-describedby="client-snackbar"
      message={(
        <Typography variant="subheading" style={{ color: '#FFFFFF' }}>
          {text}
        </Typography>
      )} />
  </Snackbar>
))
