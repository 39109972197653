import React from 'react'
import { FormControl, Select, OutlinedInput, MenuItem, Chip, withStyles, InputLabel, Input } from '@material-ui/core'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { firestore } from 'firebase'
import { withFirebase } from '../../Firebase'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const BrandDropdown = ({ brand, onBrandChange, className, brands, classes }) => (
  <FormControl fullWidth className={className}>
    <InputLabel htmlFor="select-multiple-chip">Brands</InputLabel>
    <Select
      displayEmpty
      multiple
      value={brand}
      onChange={e => onBrandChange(e.target.value)}
      input={<Input id="select-multiple-chip" />}
      renderValue={selected => (
        <div className={classes.chips}>
          {selected.map(value => (
            <Chip key={value} label={brands.filter(b => b.id === value)[0].name} className={classes.chip} />
          ))}
        </div>
      )}
      MenuProps={MenuProps}>
      {brands.map(b => (
        <MenuItem key={b.id} value={b.id}>
          {b.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default compose(
  withFirebase,
  withState('brand', 'setBrand', ({ value }) => (typeof value === 'string' ? value.split(', ') :  value)),
  withState('brands', 'setBrands', []),
  withHandlers({
    onBrandChange: props => (val) => {
      props.setBrand(val)
      props.onChange(val)
    },
  }),
  lifecycle({
    async componentDidMount() {
      /** @type firestore.CollectionReference */
      const brandsRef = this.props.firebase.firestore.collection('brands')
      const brands = await brandsRef.get()
      this.props.setBrands(brands.docs.map(b => ({ id: b.id, ...b.data() })))
    },
  }),
  withStyles(styles, { withTheme: true }),
)(BrandDropdown)

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
})
