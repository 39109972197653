import React from 'react'
import { Typography, Paper, TextField, Grid, FormGroup, FormControlLabel, Checkbox, Button } from '@material-ui/core'
import { compose, withState, lifecycle } from 'recompose'
import { firestore } from 'firebase'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import * as ROUTES from '../../../constants/routes'

import Breadcrumb from '../../Common/Breadcrumb'
import Container from '../../Common/Container'
import { withFirebase } from '../../Firebase'
import Info from './Info'
import Notification from './notification'

class AddMembership extends React.Component {
  static propTypes = {
    availableServices: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.string,
    })),
  };

  state = {
    name: null,
    description: null,
    startDate: moment().add(1, 'day').format('YYYY-MM-DD'),
    endDate: moment().add(32, 'days').format('YYYY-MM-DD'),
    services: [],
    price: null,
    isNotificationOpen: false,
    status: 'warning',
    text: null,
  };

  handleCloseNotification = () => this.setState({ isNotificationOpen: false });

  showNotification = (text, status) => this.setState({
    isNotificationOpen: true,
    text,
    status,
  });

  onChange = (key, value) => {
    this.setState({ [key]: value })
  }

  setService = (name) => {
    this.setState((state) => {
      /** @type {String[]} */
      const services = state.services
      if (services.indexOf(name) > -1) {
        return { services: services.filter(s => s !== name) }
      }

      return { services: [...services, name] }
    })
  }

  cancelAdd = () => this.props.history.goBack();

  addMembership = () => {
    const { name, description, startDate, endDate, services, price } = this.state

    if (!name) {
      return this.showNotification('Name for a membership plan is required', 'warning')
    }

    if (!description) {
      return this.showNotification('Description for a membership plan is required and suggested', 'warning')
    }

    if (endDate <= startDate) {
      return this.showNotification('End Date cannot be less than the start date', 'warning')
    }

    if (services.length === 0) {
      return this.showNotification('Please select atleast one service included in the package', 'warning')
    }

    if (!price || price <= 0) {
      return this.showNotification('Price is required and has to be more than zero', 'warning')
    }

    /** @type firestore.CollectionReference */
    const membershipRef = this.props.firebase.firestore.collection('memberships')
    membershipRef.add({
      name,
      description,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      services,
      price,
      created_at: new Date(),
      active: true,
    }).then(() => {
      this.props.history.push(ROUTES.MEMBERSHIP_VIEW)
    }).catch((err) => {
      console.error(err)
      this.showNotification(err.message, 'error')
    })
  }

  render() {
    return (
      <>
        <Breadcrumb title="Memberships / Add New" />
        <Container>
          <Grid container direction="row" lg={12} spacing={40}>
            <Grid item md={8} xs={12}>
              <Typography variant="display1">
                Add a new Membership
              </Typography>
              <Paper fullWidth style={{ padding: 20, marginTop: 20 }}>
                <form>
                  <Grid container lg={12}>
                    <TextField
                      fullWidth
                      id="name"
                      label="Name"
                      placeholder="What would you name the Membership plan?"
                      value={this.state.name}
                      onChange={e => this.onChange('name', e.target.value)}
                      margin="normal"
                      variant="outlined" />
                    <TextField
                      fullWidth
                      multiline
                      id="description"
                      label="Description"
                      placeholder="Define the membership plan in a few words"
                      rows={3}
                      value={this.state.description}
                      onChange={e => this.onChange('description', e.target.value)}
                      margin="normal"
                      variant="outlined" />
                    <Grid container direction="row" lg={12}>
                      <Grid item md={6} xs={6} style={{ paddingRight: 10 }}>
                        <TextField
                          fullWidth
                          id="startDate"
                          label="Start Date"
                          type="date"
                          placeholder="Start Date of the Membership"
                          value={this.state.startDate}
                          onChange={e => this.onChange('startDate', e.target.value)}
                          margin="normal"
                          variant="outlined" />
                      </Grid>
                      <Grid item md={6} xs={6} style={{ paddingLeft: 10 }}>
                        <TextField
                          fullWidth
                          id="endDate"
                          label="End Date"
                          type="date"
                          placeholder="End Date of the Membership"
                          value={this.state.endDate}
                          onChange={e => this.onChange('endDate', e.target.value)}
                          margin="normal"
                          variant="outlined" />
                      </Grid>
                    </Grid>
                    <Grid item lg={12} style={{ paddingTop: 20, paddingBottom: 20 }}>
                      <Typography variant="headline" gutterBottom>
                        Services included in this package
                      </Typography>
                      <FormGroup row>
                        {this.props.availableServices.map(service => (
                          <FormControlLabel
                            key={service.id}
                            control={(
                              <Checkbox
                                checked={this.state.services && this.state.services[service.name]}
                                onChange={() => this.setService(service.name)}
                                color="primary"
                                value={service.name} />
                            )}
                            style={{ marginLeft: 10, marginRight: 10 }}
                            label={<Typography variant="subheading">{service.name}</Typography>} />
                        ))}
                      </FormGroup>
                    </Grid>
                    <TextField
                      fullWidth
                      id="price"
                      label="Price (INR)"
                      type="number"
                      placeholder="Price of the entire package"
                      value={this.state.price}
                      onChange={e => this.onChange('price', e.target.value)}
                      margin="normal"
                      variant="outlined" />
                    <Grid container direction="row" style={{ marginTop: 20, marginBottom: 20 }}>
                      <Button variant="raised" color="primary" size="large" onClick={this.addMembership}>
                        Add New Membership Plan
                      </Button>
                      <Button variant="flat" color="primary" onClick={this.cancelAdd} style={{ marginLeft: 20 }}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
            <Info />
          </Grid>
          <Notification
            isOpen={this.state.isNotificationOpen}
            handleClose={this.handleCloseNotification}
            text={this.state.text}
            status={this.state.status} />
        </Container>
      </>
    )
  }
}

export default compose(
  withFirebase,
  withRouter,
  withState('availableServices', 'setAvailableServices', []),
  lifecycle({
    async componentDidMount() {
      /** @type firestore.CollectionReference */
      const servicesRef = this.props.firebase.firestore.collection('available_services')
      const _services = (await servicesRef.get()).docs
      const services = _services.map(s => ({ id: s.id, ...s.data() }))
      this.props.setAvailableServices(services)
    },
  }),
)(AddMembership)
