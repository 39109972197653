import React from 'react'
import { Typography, Grid } from '@material-ui/core'

export default () => (
  <Grid item md={4} xs={12}>
    <Typography variant="display1" gutterBottom style={{ marginBottom: 20 }}>
      Important Information
    </Typography>

    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <Typography variant="headline">Name</Typography>
      <Typography variant="body1">
        The name of the Membership. This is visible to the Customers. Make sure you choose a nice name.
      </Typography>
    </div>

    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <Typography variant="headline">Description</Typography>
      <Typography variant="body1">
        The description of the Membership plan. This is usually internal which make EMSeva employees understand what the membership plan relates to. Make sure that you describe the membership plan briefly.
      </Typography>
    </div>

    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <Typography variant="headline">Start Date</Typography>
      <Typography variant="body1">
        This is the date when the membership plan starts from. This means the customers would see this plan post this date.
      </Typography>
    </div>

    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <Typography variant="headline">End Date</Typography>
      <Typography variant="body1">
        This is the date when the membership plan ends. This means the customers would stop seeing this plan post this date.
      </Typography>
    </div>

    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <Typography variant="headline">Services Included</Typography>
      <Typography variant="body1">
        Choose the services that you intend to give as a part of the package to the customers
      </Typography>
    </div>

    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <Typography variant="headline">Price</Typography>
      <Typography variant="body1">
        This is the price of the package that would be displayed to the customers. This is also the price the customers would be charged for.
      </Typography>
    </div>
  </Grid>
)
