import React from 'react'
import { Typography, Grid } from '@material-ui/core'

export default () => (
  <Grid item lg={4} md={12} direction="column" spacing={40}>
    <div style={{ padding: 20 }}>
      <Typography variant="headline" style={{ marginBottom: 20 }}>
        Important Information
      </Typography>
      <div style={{ margin: '20px 0px' }}>
        <Typography variant="title" gutterBottom>
          Name
        </Typography>
        <Typography variant="body1" gutterBottom>
          The name of the Mechanic
        </Typography>
      </div>
      <div style={{ margin: '20px 0px' }}>
        <Typography variant="title" gutterBottom>
          Phone Number
        </Typography>
        <Typography variant="body1" gutterBottom>
          The primary contact number of the mechanic where he could be contacted upon
        </Typography>
      </div>
      <div style={{ margin: '20px 0px' }}>
        <Typography variant="title" gutterBottom>
          Alternate Phone Number
        </Typography>
        <Typography variant="body1" gutterBottom>
          An alternate number in case the primary contact number is not reachable
        </Typography>
      </div>
      <div style={{ margin: '20px 0px' }}>
        <Typography variant="title" gutterBottom>
          Email address (optional)
        </Typography>
        <Typography variant="body1" gutterBottom>
          An email address for the mechanic
        </Typography>
      </div>
      <div style={{ margin: '20px 0px' }}>
        <Typography variant="title" gutterBottom>
          Address
        </Typography>
        <Typography variant="body1" gutterBottom>
          The residentail address of the mechanic
        </Typography>
      </div>
      <div style={{ margin: '20px 0px' }}>
        <Typography variant="title" gutterBottom>
          Preferred area of work
        </Typography>
        <Typography variant="body1" gutterBottom>
          The area / location where mechanic is comfortable working with. Please add at-least one location
        </Typography>
      </div>
    </div>
  </Grid>
)
