import React from 'react'
import { Paper, TableHead, TableRow, TableCell, Table, TableBody, CircularProgress, Grid, Typography } from '@material-ui/core'
import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { firestore } from 'firebase'
import { withFirebase } from '../../Firebase'
import Item from './item'

const ServiceChecklistList = ({ services, setLastUpdated }) => (
  <Paper>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell><Typography variant="subheading">Name</Typography></TableCell>
          <TableCell><Typography variant="subheading">Price</Typography></TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {!services && (
          <TableRow>
            <TableCell colSpan={3} style={{ padding: 50 }} align="center">
              <CircularProgress />
            </TableCell>
          </TableRow>
        )}
        {services && Object.keys(services).length === 0 && (
          <TableRow>
            <TableCell colSpan={2}>
              <Grid container xs={12} direction="column" alignItems="center" style={{ padding: 30 }}>
                <Typography variant="display2" gutterBottom>
                  No Services Added to your checklist
                </Typography>
                <Typography variant="headline" gutterBottom>
                  Go ahead, add a new Service to your checklist
                </Typography>
              </Grid>
            </TableCell>
          </TableRow>
        )}
        {services && Object.keys(services).map((id) => {
          const service = services[id]
          return (
            <Item key={id} id={id} service={service} setLastUpdated={setLastUpdated} />
          )
        })}
      </TableBody>
    </Table>
  </Paper>
)

export default compose(
  withFirebase,
  withState('services', 'setServices', null),
  withHandlers({
    getServices: props => async () => {
      /** @type firestore.CollectionReference */
      const servicesRef = props.firebase.firestore.collection('available_services')
      const _services = (await servicesRef.get()).docs
      const services = {}
      _services.forEach((s) => {
        services[s.id] = s.data()
      })
      props.setServices(services)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getServices()
    },
    componentWillReceiveProps(nextProps) {
      if (this.props.lastUpdated !== nextProps.lastUpdated) {
        this.props.getServices()
      }
    },
  }),
)(ServiceChecklistList)
