import React, { Component } from 'react'
import GoogleMapLoader from 'react-google-maps-loader'
import GooglePlacesSuggest from 'react-google-places-suggest'
import TextField from '../Common/TextField'

const MY_API_KEY = 'AIzaSyApVKHRSCa_BArsfMpfYPu9eYV62V-_u7M' // fake

export default class GoogleSuggest extends React.Component {
  state = {
    search: '',
    value: '',
  }

  handleInputChange = (text) => {
    this.setState({ search: text, value: text })
  }

  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    this.setState({ search: '', value: geocodedPrediction.formatted_address })
    if (this.props.onChange) {
      this.props.onChange(geocodedPrediction, originalPrediction)
    }
  }

  handleNoResult = () => {
    console.log('No results for ', this.state.search)
  }

  render() {
    const { search, value } = this.state
    return (
      <GoogleMapLoader
        params={{
          key: MY_API_KEY,
          libraries: 'places,geocode',
        }}
        render={googleMaps => googleMaps && (
          <GooglePlacesSuggest
            googleMaps={googleMaps}
            autocompletionRequest={{
              input: search,
              // Optional options
              // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
            }}
            // Optional props
            onNoResult={this.handleNoResult}
            onSelectSuggest={this.handleSelectSuggest}
            textNoResults="My custom no results text" // null or "" if you want to disable the no results item
            customRender={prediction => (
              <div className="customWrapper">
                {prediction
                  ? prediction.description
                  : 'My custom no results text'}
              </div>
            )}>
            <TextField
              id="searchText"
              label="Location"
              placeholder="Start typing to search for location on Google..."
              value={value}
              onChange={this.handleInputChange} />
          </GooglePlacesSuggest>
        )
        } />
    )
  }
}
